import { API_URLS } from '../constants/endpoints';
import APIAbstract from './APIAbstract';

export interface LoginPayload {
  username: string;
  password: string;
}

class UserAPI extends APIAbstract {

  public login(payload: LoginPayload) {
    return this.post(API_URLS.LOGIN, payload);
  }

  public signUp(payload:any) {
    return this.post(API_URLS.SIGNUP, payload);
  }

  public updateUser(payload:any) {
    return this.put(API_URLS.USERUPDATE, payload);
  }

  public forgot(payload:any) {
    return this.post(API_URLS.FORGOT, payload);
  }

  public reset(payload:any) {
    return this.post(API_URLS.RESETPWD, payload);
  }

  public upload(payload:any) {
    return this.post(API_URLS.UPLOAD, payload);
  }

  public getUserDetails() {
    return this.get(API_URLS.USERDETAILS);
  }

}

export default new UserAPI();

import React, { useMemo, useState } from 'react';
import Evaldhannes from 'assets/images/Evaldhannes.png';
import Arun from 'assets/images/Arun.png';
import Yash from 'assets/images/Yash.png';
import Evan from 'assets/images/Evan.png';
import Mukul from 'assets/images/Mukul.png';
import Robbie from 'assets/images/Robbie.png';
import LinkedInIcon from 'assets/images/linkedin-icon.png';
import NcImage from 'shared/NcImage/NcImage';
import Team from 'assets/images/team.png';



const OurAdvisory = () => {

   return (

      <div className="our-team-section text-center">
         

      </div>



   );
};

export default OurAdvisory;

import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import brandBlogImg from "../../assets/images/subscribe-me.png"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";

export interface SectionBrandBlogProps {
    className?: string;
    blogId?: number;
}

const SectionBrandBlog: FC<SectionBrandBlogProps> = ({ className = "", blogId ='640aeec0aa43bb4e5db24094' }) => {
    const navigate = useNavigate()
    return (
        <div
            className={`nc-SectionSubscription relative flex flex-col lg:flex-row lg:items-center ${className}`}
            data-nc-id="SectionSubscription"
        >
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:w-1/2">
                <NcImage src={brandBlogImg} />
            </div>
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:w-1/2 px-8 py-8">
                <h2 className="font-semibold text-4xl">The Benefits of Offering Tradable Subscriptions</h2>
                <span className="block mt-5 text-neutral-500 dark:text-neutral-100">
                Tradable subscriptions are a new trend in the world of subscription services that offer customers a new level of flexibility and value. These subscriptions allow users to trade, sell, or gift their unused subscription time to others on a secondary market...
                </span>

                <ButtonPrimary
                    type="submit"
                    className="mt-8"
                    onClick={()=>navigate(`/blog/${blogId}`, { state: { id: blogId } })}
                >
                    Read More
                </ButtonPrimary>
            </div>

        </div>
    );
};

export default SectionBrandBlog;

import create, { Mutate, StoreApi, UseBoundStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { AxiosResponse } from 'axios';
import useLayoutStore from './useLayoutStore';
import { queryClient } from '../config/queryClient';
import apiClient from '../config/apiClient';
import { API_URLS } from '../constants/endpoints';

interface AuthStore {
  accessToken: string;
  refreshToken: string;
  rememberMe: boolean;
  user: any;
  walletAddress:string;
  walletConnected:boolean;
  accountBalance: string;
  usdtPrice: string;
  setUser: (user: any) => void;
  setAccessToken: (accessToken: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  setUSDTPrice: (usdtPrice: string) => void;
  setAccountBalance: (accountBalance: string) => void;
  setRememberMe: (rememberMe: boolean) => void;
  setWalletConnected: (walletConnected: boolean) => void;
  setWalletAddress: (walletAddress:string)=>void;
  resetState: () => void;
}

type UseBearStore = UseBoundStore<
  Mutate<StoreApi<AuthStore>, [['zustand/persist', AuthStore]]>
>;

const useAuthStore: UseBearStore = create(
  persist(
    (set, get) => ({
      accessToken: '',
      refreshToken: '',
      rememberMe: false,
      user: null,
      walletAddress:'',
      walletConnected:false,
      accountBalance: '0.00',
      usdtPrice: '0',
      setUser: (user: any) => set({ user }),
      setAccessToken: (accessToken: string) => set({ accessToken }),
      setRefreshToken: (refreshToken: string) => set({ refreshToken }),
      setAccountBalance: (accountBalance: string) => set({ accountBalance }),
      setUSDTPrice: (usdtPrice: string) => set({ usdtPrice }),
      setRememberMe: (rememberMe: boolean) => set({ rememberMe }),
      setWalletAddress: (walletAddress: string) => set({ walletAddress }),
      setWalletConnected: (walletConnected: boolean) => set({walletConnected}),
      resetState: () => {
        queryClient.removeQueries();
        useLayoutStore.getState().resetState();
        set({ accessToken: '', refreshToken: '', rememberMe: false,walletConnected:false, walletAddress:'', user: null, accountBalance: '0.00', usdtPrice: '0' });
      },
 
    }),
    {
      name: 'yoftAuthStorage',
    },
  ),
);

export default useAuthStore;

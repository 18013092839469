import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import useAuthStore from '../store/useAuthStore';
import UserAPI, { LoginPayload } from '../api/UserAPI';
import shallow from 'zustand/shallow';
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search)
  const redirect = queryParams.get("redirect")

  const [setAccessToken, setRefreshToken, accessToken, user, setUser] = useAuthStore(
    (state) => [
      state.setAccessToken,
      state.setRefreshToken,
      state.accessToken,
      state.user,
      state.setUser,
    ],
    shallow,
  );

  const { mutate: login, ...loginInfo } = useMutation<
    AxiosResponse<any>,
    Error,
    LoginPayload
  >((loginPayload) => UserAPI.login(loginPayload), {
    onSuccess: ({ data: response }) => {
      setTimeout(() => {
        setAccessToken(response.data.token);
        setRefreshToken(response.data.token);
        setUser({ user: response.data.user, isLoading: loginInfo.isLoading});
        if(redirect) {
          navigate(redirect)
        } else {
          navigate('/')
        }
      }, 1);
    },
  });

 

  // const { mutate: register, ...registerInfo } = useMutation<AxiosResponse, Error>(
  //   (payload) => apiClient.post(API_URLS.REGISTER, payload),
  //   {
  //     onError: (e) => notification.error(e),
  //   },
  // );
  //
  // const { mutate: restaurantRegister, ...restaurantRegisterInfo } = useMutation<
  //   AxiosResponse,
  //   Error
  //   >((payload) => apiClient.post(API_URLS.RESTAURANT_REGISTER, payload), {
  //   onError: (e) => notification.error(e),
  // });
  //
  // const { mutate: resetPassword, ...resetPasswordInfo } = useMutation<
  //   AxiosResponse<ResetPasswordApiResponse>,
  //   Error,
  //   ResetPasswordPayload
  //   >(
  //   (payload) =>
  //     apiClient.post(API_URLS.AUTH_RESET_PASSWORD, {
  //       ...payload,
  //       source: constants.USER_ACCOUNT_TYPE.Business,
  //     }),
  //   {
  //     onError: (e) => notification.error(e),
  //   },
  // );
  //
  // const { mutate: changePassword, ...changePasswordInfo } = useMutation<
  //   AxiosResponse<ChangePasswordApiResponse>,
  //   Error,
  //   ChangePasswordPayload
  //   >(
  //   (payload) =>
  //     apiClient.post(API_URLS.AUTH_CHANGE_PASSWORD, {
  //       ...payload,
  //       source: constants.USER_ACCOUNT_TYPE.Business,
  //     }),
  //   {
  //     onError: (e) => notification.error(e),
  //   },
  // );

  // const { mutate: invitationToken, ...invitationTokenInfo } = useMutation<
  //   AxiosResponse,
  //   Error,
  //   string
  //   >((token) => apiClient.get(`${API_URLS.INVITATION_TOKEN}/${token}`));

  return {
    accessToken,
    login,
    loginInfo,
    user,
    isLoading: loginInfo?.isLoading,
    // register,
    // registerInfo,
    // restaurantRegister,
    // restaurantRegisterInfo,
    // resetPassword,
    // resetPasswordInfo,
    // changePassword,
    // changePasswordInfo,
  };
};

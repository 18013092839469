import React from "react";
import { Tab } from "@headlessui/react";
import { personNames } from "contains/fakeData";
import Avatar from "shared/Avatar/Avatar";
import VerifyIcon from "components/VerifyIcon";
import moment from "moment";
import { Link } from "react-router-dom";
import { transactionLink } from "config/web3.config";
import Explore from "../../assets/images/explore.svg";
import { FiClock } from "react-icons/fi";
import { FaCircle } from 'react-icons/fa';
import { HiOutlineCheckCircle } from "react-icons/hi";

const TabDetail = (props: any) => {
  //const TABS = ["History", "Owners"];
  const TABS = ["How To Redeem"];

  const renderTabBidHistory = () => {
    return (
      <ul className="divide-y divide-neutral-100 dark:divide-neutral-700">
        {props?.historyList.map((historyList: any, index: number) => {
          return (
            <li
              key={index}
              className={`relative py-4 ${
                index % 2 === 1 ? "bg-neutradl-100" : ""
              }`}
            >
              <div className="flex items-center">
                <Avatar
                  imgUrl={
                    index + 1 === props?.historyList.length
                      ? props?.data?.brandId?.brandLogoS3URL
                      : historyList?.ownerId?.profile
                  }
                  sizeClass="h-9 w-9"
                  radius="rounded-full"
                />

                <span className="ml-4 text-neutral-500 dark:text-neutral-400 flex flex-col">
                  <span className="flex items-center text-sm">
                    <span className="font-medium text-neutral-900 dark:text-neutral-200 ml-1">
                      {index + 1 === props?.historyList.length
                        ? "Minted by"
                        : historyList?.text}{" "}
                      {index + 1 === props?.historyList.length
                        ? props.data?.brandId?.brandName
                        : historyList?.ownerId?.name}
                    </span>
                    {historyList?.transactionId && (
                      <Link
                        className="px-4"
                        onClick={() =>
                          window.open(
                            transactionLink + "/" + historyList?.transactionId,
                            "_blank"
                          )
                        }
                        to="#"
                      >
                        {" "}
                        <img src={Explore} width="15" />
                      </Link>
                    )}
                  </span>
                  <span className="text-xs mt-1">
                    {moment(historyList?.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </span>
                </span>
              </div>
            </li>
          );
        })}

        <div className="flex items-center mt-2">
          <FiClock className="text-sm text-green-500 mr-2" />
          <span className="text-xs">
            {moment(props.historyList?.createdAt).format("MMMM Do, YYYY")}
          </span>
        </div>

        <span className="text-sm mt-4">
          Tradable Membership listed by {props.data?.brandId?.brandName}
        </span>
      </ul>
    );
  };



  const renderHowToRedeem = () => {
    return (
      <ul className="list-decimal ml-6 mt-4 leading-loose">
        <li className="text-neutral-900 dark:text-neutral-200 text-sm font-medium leading-loose flex items-center">
          <HiOutlineCheckCircle className="mr-2 text-green-500" />
          Buy the membership from above.
        </li>
        <li className="text-neutral-900 dark:text-neutral-200 text-sm font-medium leading-loose flex items-center">
          <HiOutlineCheckCircle className="mr-2 text-green-500" />
          You will receive a confirmation email from YOFT along with a unique code.
        </li>
        <li className="text-neutral-900 dark:text-neutral-200 text-sm font-medium leading-loose flex items-center">
          <HiOutlineCheckCircle className="mr-2 text-green-500" />
          Connect with the brand and mention your unique code.
        </li>
        <li className="text-neutral-900 dark:text-neutral-200 text-sm font-medium leading-loose flex items-center">
          <HiOutlineCheckCircle className="mr-2 text-green-500" />
          The brand will activate your membership.
        </li>
      </ul>
    );
  };
  
  
  
  const renderTabProvenance = () => {
    return (
      <ul className="divide-y divide-neutral-100 dark:divide-neutral-700">
        {[1, 1, 1, 1, 1].map((_, index) => (
          <li
            key={index}
            className={`relative py-4 ${
              index % 2 === 1 ? "bg-neutradl-100" : ""
            }`}
          >
            <div className="flex items-center">
              <Avatar sizeClass="h-10 w-10" radius="rounded-full" />
              <span className="ml-4 text-neutral-500 dark:text-neutral-400 flex flex-col">
                <span className="flex items-center text-sm">
                  <span className="">
                    {Math.random() > 0.5 ? "Listed by" : "Minted by"}
                  </span>

                  <span className="font-medium text-neutral-900 dark:text-neutral-200 ml-1">
                    Martoutaa
                  </span>
                </span>
                <span className="text-xs mt-1">Jun 14 - 4:12 PM</span>
              </span>
            </div>

            <span className="absolute inset-0 rounded-md focus:z-10 focus:outline-none focus:ring-2 ring-blue-400"></span>
          </li>
        ))}
      </ul>
    );
  };

  const renderTabOwner = () => {
    return (
      <div className="divide-y divide-neutral-100 dark:divide-neutral-700">
        <div className="flex py-4">
          <Avatar
            imgUrl={props?.data?.brandId?.brandLogoS3URL}
            sizeClass="h-9 w-9"
            radius="rounded-full"
          />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
            <span className="text-sm">{props?.data?.brandId?.brandName}</span>
            <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
              <span className="text-xs">
                {props?.data?.edition - props?.data?.editions?.length}/
                {props?.data?.edition} on sale for{" "}
                <span>
                  {props?.data?.price} {props?.data?.currency || "MATIC"}
                </span>{" "}
                each
              </span>
              {/* <VerifyIcon iconClass="w-4 h-4" /> */}
            </span>
          </span>
        </div>
        {props?.data?.editions &&
          props?.data?.editions.map((edition: any) => {
            return (
              <div className="flex py-4">
                <Avatar
                  imgUrl={edition?.ownerId?.profile}
                  sizeClass="h-9 w-9"
                  radius="rounded-full"
                />
                <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                  <span className="text-sm">{edition?.ownerId?.name}</span>
                  <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                    <span className="text-xs">
                      {edition.isOpenForSale ? (
                        <>
                          {"1 on sale for "}{" "}
                          <span>
                            {edition.saleType.price}{" "}
                            {edition.saleType.currency || "MATIC "}
                          </span>
                        </>
                      ) : (
                        "1 not on sale "
                      )}
                    </span>
                    {edition.transactionId && (
                      <Link
                        className="px-4"
                        onClick={() =>
                          window.open(
                            transactionLink + "/" + edition.transactionId,
                            "_blank"
                          )
                        }
                        to="#"
                      >
                        <img src={Explore} width="15" />
                      </Link>
                    )}
                  </span>
                </span>
              </div>
            );
          })}
      </div>
    );
  };

  const renderTabItem = (item: string) => {
    switch (item) {
      case "History":
        return renderTabBidHistory();

      case "Provenance":
        return renderTabProvenance();

      case "Owners":
        return renderTabOwner();

      case "How To Redeem":
        return renderHowToRedeem();
      default:
        return null;
    }
  };

  return (
    <div className="w-full pdx-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex justify-start pd-1 space-x-2.5 rounded-full bordedr border-neutral-300 dark:border-neutral-500">
          {TABS.map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                `px-3.5 sm:px-8 py-1.5 sm:py-2 text-xs sm:text-sm leading-5 font-medium rounded-full focus:outline-none focus:ring-2 ring-primary-300 ${
                  selected
                    ? "bg-neutral-900 dark:bg-neutral-100 text-white dark:text-neutral-900"
                    : "text-neutral-700 dark:text-neutral-300 bg-neutral-100/70 dark:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100"
                }`
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4">
          {TABS.map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className={
                "rounded-xl focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 "
              }
            >
              {renderTabItem(tab)}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TabDetail;

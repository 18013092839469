import { useAppSelector } from "app/hooks";
import { selectCurrentMediaRunning } from "app/mediaRunning/mediaRunning";
import React, { FC } from "react";

interface VideoForNftProps {
  src?: string;
  className?: string;
  nftId?: string;
}

const VideoForNft: FC<VideoForNftProps> = ({
  className = "",
  src = "",
}) => {


  return (
    <div
    className={className}
      title="Play"
      dangerouslySetInnerHTML={{
        __html: `<video src=${src}
        loop={true}
        autoPlay={true}
        style=height:100%;width:100% }
         controlsList="nodownload" />`,
      }}
    />
  );
};

export default VideoForNft;

import { queryClient } from "config/queryClient";
import React from "react";
import { QueryClientProvider } from "react-query";
import MyRouter from "routers/index";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
     <QueryClientProvider client={queryClient}>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>
    </QueryClientProvider>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      closeButton={true}
      newestOnTop={true}
      rtl={false}
      limit={5}
      enableMultiContainer={true}
      draggable={false}
      theme="colored"
      pauseOnFocusLoss
      pauseOnHover
    />
    </>
   
  );
}

export default App;
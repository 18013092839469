import React, { FC, useMemo } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { catchErrorMessage } from "utils/helper";
import { useAuth } from "hooks/useAuth";
import { useUserData } from "hooks/useUserData";
import { toast } from "react-toastify";

export interface PageLoginProps {
  className?: string;
}

export const LoginSchema = Yup.object().shape({
  username: Yup.string().email('Enter a valid email').required('Required field'),
  password: Yup.string()
    .required('Required field')
});

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { login, loginInfo } = useAuth();
  const {
     userDetails: { isLoading },
  } = useUserData();
  const loading = useMemo(() => loginInfo?.isLoading || isLoading, [loginInfo?.isLoading, isLoading]);


  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });


  const onSubmit = (data: any) => {
    login(data, {
      onError: (e) => {
        toast.error(catchErrorMessage(e));
      },
   });
  };


  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Yoft | Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>

              <Controller
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1 validate"  {...field} />
                )}
                name="username"
                defaultValue=""
              />
              <p className='input-error'>{errors?.username?.message}</p>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm text-green-600">
                  Forgot password?
                </Link>
              </span>
              <Controller
                control={control}
                render={({ field, fieldState }) => (
                  <Input type="password"  className="validate mt-1"  {...field} />

                )}
                name="password"
                defaultValue=""
              />
              <p className='input-error'>{errors?.password?.message}</p>
            </label>
            <ButtonPrimary loading={loading} type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" className="text-green-600">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;

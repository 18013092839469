import React, { FC, useEffect, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import CoinTelegraphImg from "assets/images/media-partners/cointelegraph.png"
import YourStoryImg from "assets/images/media-partners/Yourstory.png"
import AsianNewsImg from "assets/images/media-partners/asian-news.png"
import BigNewsImg from "assets/images/media-partners/bignewsnetwork.jpg"
import BusinessStandardImg from "assets/images/media-partners/business-standard-logo.png"
import BwBusinessImg from "assets/images/media-partners/bwBusiness-world.jpg"
import DailyHuntImg from "assets/images/media-partners/Dailyhunt.png"
import DalalStreetImg from "assets/images/media-partners/Dalalstreet.png"
import EasternHeraldImg from "assets/images/media-partners/Eastern-herald.png"
import GadgetImg from "assets/images/media-partners/gadget.png"
import InlImg from "assets/images/media-partners/inl.png"
import LatestlyImg from "assets/images/media-partners/Latestly.png"
import MixPointImg from "assets/images/media-partners/Mixpoint.png"
import NewsHubImg from "assets/images/media-partners/Newshub.png"
import NewsVoirImg from "assets/images/media-partners/NewsVoir.png"
import NOFImg from "assets/images/media-partners/NOF.png"
import PTIImg from "assets/images/media-partners/PTI.png"
import SmallCafeImg from "assets/images/media-partners/smallcafe.png"
import TheConnectImg from "assets/images/media-partners/TheConnect.png"
import TheTruthTHoneImg from "assets/images/media-partners/thetruthone.png"
import TheWeekImg from "assets/images/media-partners/theweek.png"
import Zee5Img from "assets/images/media-partners/Zee5.jpeg"


import Loader from "components/Loader";

export interface SectionSliderMediaProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: any;
}

const MediaImg = [
  CoinTelegraphImg,
  YourStoryImg,
  AsianNewsImg,
  BigNewsImg,
  BusinessStandardImg,
  BwBusinessImg,
  DailyHuntImg,
  DalalStreetImg,
  EasternHeraldImg,
  GadgetImg,
  InlImg,
  LatestlyImg,
  MixPointImg,
  NewsHubImg,
  NewsVoirImg,
  NOFImg,
  PTIImg,
  SmallCafeImg,
  TheConnectImg,
  TheTruthTHoneImg,
  TheWeekImg,
  Zee5Img,
];



const SectionSliderMedia: FC<SectionSliderMediaProps> = ({
  heading = "Browse by Brands",
  subHeading = "Explore the tradeable subscriptions from top brands.",
  className = "",
  itemClassName = "",
  data = [],
}) => {
  const sliderRef = useRef(null);
  const id = new Date().valueOf().toString();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current ) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 5,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4,
        },
        1024: {
          gap: 20,
          perView: 3.4,
        },
        768: {
          gap: 20,
          perView: 3,
        },
        640: {
          gap: 20,
          perView: 2.3,
        },
        500: {
          gap: 20,
          perView: 1.4,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS]);

  return (
    <div className={`nc-SectionSliderMedia ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading desc={subHeading} hasNextPrev>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {MediaImg.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <CardCategory5
                  index={index}
                  featuredImage={item}
                  imgFit="object-cover"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderMedia;
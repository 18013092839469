import React, { FC } from "react";
import imagePng from "images/hero-right-3.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { Helmet } from "react-helmet";
import AnimationImg from "../../assets/images/Animation.gif"

export interface SectionHero2Props {
  children?: React.ReactNode;
  className?: string;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  return (
    <div
      className={`nc-SectionHero2 flex flex-col-reverse lg:flex-col relative ${className}`}
    >
      {/* <Helmet>
        <title>Home</title>
      </Helmet> */}
      <div className="flex flex-col lg:flex-col lg:items-center">
          <img className="w-full lg:w-[60%]" src={AnimationImg} alt="hero" />
        <div className="text-center flex flex-col lg:mt-[-80px]">
          <h2 className="font-semibold text-4xl md:text-5xl xl:text-6xl !leading-[114%] ">
            Tradable Memberships: A Win-Win for Brands and Subscribers.
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-100 mt-8">
            For brands: Increase retention rates and revenue. For subscribers: Enjoy discounts and flexible subscriptions.
          </span>

          <ButtonPrimary className=" mx-auto mt-8">
          <a href="https://form.jotform.com/231481258833459" target={"_blank"}>
                        Become a brand partner
                    </a>
          </ButtonPrimary>
        </div>

      </div>

      {/* <div className="z-10 mb-12 lg:mb-0 lg:-mt-20 xl:-mt-48 w-full">
        <HeroSearchForm />
      </div> */}
    </div>
  );
};

export default SectionHero2;

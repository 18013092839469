import React from "react";

const Loader = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
      <div className="bg-white p-5 rounded-lg shadow-lg">
        <svg className="animate-spin h-10 w-10 text-gray-900" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 018 8v-2c0-4.418-3.582-8-8-8V0c6.627 0 12 5.373 12 12h-4zm-8 8a8 8 0 01-8-8h-2a10 10 0 0010 10v-2zm8 0a8 8 0 01-8 8v2a10 10 0 0010-10h-2z"></path>
        </svg>
      </div>
    </div>
  );
};

export default Loader;

import create from 'zustand';

interface LayoutStore {
  openNavMenu: boolean;
  setOpenNavMenu: (openNavMenu: boolean) => void;
  resetState: () => void;
}

const useLayoutStore = create<LayoutStore>((set) => ({
  openNavMenu: false,
  setOpenNavMenu: (openNavMenu: boolean) => set({ openNavMenu }),
  resetState: () => {
    set({ openNavMenu: false });
  },
}));

export default useLayoutStore;

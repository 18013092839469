import React, { FC, useMemo, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { catchErrorMessage } from "utils/helper";
import { useAuth } from "hooks/useAuth";
import { useUserData } from "hooks/useUserData";
import UserAPI from "api/UserAPI";
import { toast } from "react-toastify";

export interface PageForgotPasswordProps {
  className?: string;
}

export const ForgotSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email').required('Required field'),
});

const PageForgotPassword: FC<PageForgotPasswordProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(ForgotSchema),
    defaultValues: {
      email: '',
    },
  });


  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      let body = { ...data };
      await UserAPI.forgot(body);
      setLoading(false);
      setSuccess(true);
      toast.success('An email has been sent to you. Please follow instructions');
    } catch (e) {
      setLoading(false);
      toast.error(catchErrorMessage(e));
    }
  };


  return (
    <div className={`nc-PageForgotPassword ${className}`} data-nc-id="PageForgotPassword">
      <Helmet>
        <title>Yoft | Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forgot Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">

          {!success && (
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>

                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      type="email"
                      placeholder="example@example.com"
                      className="mt-1 validate" {...field} />
                  )}
                  name="email"
                  defaultValue=""
                />
                <p className='input-error'>{errors?.email?.message}</p>
              </label>

              <ButtonPrimary loading={loading} type="submit">Submit</ButtonPrimary>
            </form>
          )}

          {success && (
            <div className="success-message">An email has been sent to you. Please follow the instructions.</div>
          )}

          {/* ==== */}
          {!success && (
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              Back to {` `}
              <Link to="/login" className="text-green-600">
                Login
              </Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageForgotPassword;

export const chainId = 80001;
export const chainIdHex = '0x13881';
export const rpcUrls = 'https://matic-mumbai.chainstacklabs.com';
export const network_name = 'Polygon Testnet'
export const contract_type = 'ERC 1155';


export const transactionLink = 'https://mumbai.polygonscan.com/tx';

export const contractAddresses = {
    nftContractAddress: '0xD30F864518b2B756458C73567F5b52f72E07ed27',
    escrowContractAddres: '0x4E6D3b85841e463b82B3D7FBe3DbE56F96576A6C',
    ContractAddress_721:'0xee4e9204cf091f1e735ebdaf7445684a40ca25af',
};

// API Key: b316c1ffedfd8e7d773b
//  API Secret: 2d1a301fbe031a0030f779e0f7ad61ceacf661b439990edd5683fc652a8b83ca
//  JWT: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI3Yjg3NGVlMi0yNTkwLTQ0YzktYmY1Zi03MWNjNmI3NDdkYmMiLCJlbWFpbCI6Imluc3RhMmtjb29rQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiJiMzE2YzFmZmVkZmQ4ZTdkNzczYiIsInNjb3BlZEtleVNlY3JldCI6IjJkMWEzMDFmYmUwMzFhMDAzMGY3NzllMGY3YWQ2MWNlYWNmNjYxYjQzOTk5MGVkZDU2ODNmYzY1MmE4YjgzY2EiLCJpYXQiOjE2Njc4OTMxOTR9.unQDcCts27KGHxQ2c_URhKIIYJOfOGpjwLB9xRw9puw
export const torus_host = 'mumbai'
export const currency_symbol = 'MATIC';

//export const stripe_public_key= 'pk_test_51MWeKPBJJpUSfFeMqqMoXbdksb1Q1I7TXlRxZauVQHPtnOWXA3TaNA7nCKKHx3tCjlQqwASNVhYacAURsxM9V6GM00aJykS25N'
export const stripe_public_key= 'pk_live_51MWeKPBJJpUSfFeMg9ozw4sz7VXalna5PlwmiqQEUg79QcjTzU2uxKLbNQ0BFOVMigz6Nr82kGrRPZGcpZ4dUJNZ00473GyMt2'
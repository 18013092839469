import React, { FC } from "react";
import HeaderFilterSection from "components/HeaderFilterSection";
import CardNFT2 from "components/CardNFT2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Loader from "components/Loader";

//
export interface SectionGridFeatureNFT2Props {
  data?: any
  isLoader?: boolean
}

const SectionGridFeatureNFT2: FC<SectionGridFeatureNFT2Props> = ({
  data=[],
  isLoader = true
}) => {
  return (
    <div className="nc-SectionGridFeatureNFT2 relative">
      <HeaderFilterSection />
      <div className={`grid gap-6 lg:gap-8 sm:grid-cols-2 xl:grid-cols-3`}>
        {isLoader ? <Loader /> : data.map((_:any, index:number) => (
          <CardNFT2 key={index} data={_} />
        ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div> */}
    </div>
  );
};

export default SectionGridFeatureNFT2;

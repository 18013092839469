import React, { FC, useEffect } from "react";
import MainNav2LoggedBrand from "./MainNav2LoggedBrand";

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  return (
    <div id="sticky" className="nc-HeaderLogged relative w-full z-40 nav-wrapper">
      {/* NAV */}
      <MainNav2LoggedBrand />
    </div>
  );
};

export default HeaderLogged;

import { pinataApi } from '../config/pinata';


export const pinataJsonService = async (data:any) => {
  const header = {
  'Authorization': `Bearer ${process.env.REACT_APP_PINATA_SECRETKEY}`}
    return pinataApi.post('/pinning/pinJSONToIPFS', data, { headers: header })
};

export const pinataImageService = async (data:any) => {
    const header = { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${process.env.REACT_APP_PINATA_SECRETKEY}`}
      return pinataApi.post('/pinning/pinFileToIPFS', data, { headers: header })
};
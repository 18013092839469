import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import MobileImg from "../../assets/images/mobile.png"
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionMobileAppProps {
    className?: string;
}

const SectionMobileApp: FC<SectionMobileAppProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-SectionMobileApp relative flex flex-col lg:flex-row lg:items-center ${className}`}
            data-nc-id="SectionMobileApp"
        >
          
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:w-1/2">
            <p className="font-medium my-6 text-neutral-700 dark:text-neutral-300">
              Mobile App
            </p>
                <h2 className="font-semibold text-4xl">Manage All Your Subscriptions in One Place.</h2>
                <span className="block mt-5 text-neutral-500 dark:text-neutral-100">
                Never lose track of your subscriptions again! Our user-friendly app lets you easily manage all your subscriptions from a single platform. Keep track of billing dates, renewal options, and more. Say goodbye to subscription overwhelm and hello to hassle-free management with Yoft App.
                </span>
            </div>
            <div className="">
                <NcImage src={MobileImg} />
            </div>
        </div>
    );
};

export default SectionMobileApp;

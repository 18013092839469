import { API_URLS } from '../constants/endpoints';
import APIAbstract from './APIAbstract';

class CategoryAPI extends APIAbstract {

  public getCategory() {
    return this.get(API_URLS.CATEGORY);
  }

  public getCollection(id:string) {
    return this.get(`${API_URLS.COLLECTION}/${id}`);
  }
  
  public getAllCollection() {
    return this.get(`${API_URLS.GETALLCOLLECTION}`);
  }

}

export default new CategoryAPI();

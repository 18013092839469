export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const API_URLS = {
  LOGIN: `${API_BASE_URL}/user/accountLogin`,
  SIGNUP: `${API_BASE_URL}/user/createNewUser`,
  FORGOT: `${API_BASE_URL}/user/forgotPassword`,
  RESETPWD: `${API_BASE_URL}/user/resetPassword`,
  USERUPDATE: `${API_BASE_URL}/user/update`,
  CREATEBRAND: `${API_BASE_URL}/brand`,
  BLOG: `${API_BASE_URL}/blog`,
  GETUSERBRAND: `${API_BASE_URL}/brand/user`,
  GETALLCOLLECTION: `${API_BASE_URL}/nft/all-listCollection`,
  SUBSCRIPTION: `${API_BASE_URL}/subscription/addSubscription`,
  UPLOAD: `${API_BASE_URL}/upload`,
  USERDETAILS: `${API_BASE_URL}/user/userDetails`,
  CONTACT: `${API_BASE_URL}/contact`,
  MARKETPLACE: `${API_BASE_URL}/nft/listMarketPlace`,
  NFTDETAILS: `${API_BASE_URL}/nft/single`,
  NFTHISTORY: `${API_BASE_URL}/nft/history`,
  GETLINKEDNFT: `${API_BASE_URL}/nft/getLikedNfts`,
  GENERATE_NONCE: `${API_BASE_URL}/user/genrateNonce`,
  CATEGORY: `${API_BASE_URL}/category/list`,
  WALLET_AUTHENTICATE:`${API_BASE_URL}/user/login`,
  COLLECTION: `${API_BASE_URL}/nft/listCollection`,
  ADD_NFT: `${API_BASE_URL}/nft/addNft`,
  UPDATEEDITION: `${API_BASE_URL}/nft/updateEdition`,
  WERTBUY: `${API_BASE_URL}/wert/buy`,
  COLLECTEDNFT: `${API_BASE_URL}/nft/getCollectedNfts`,
  LIKEDEDNFT: `${API_BASE_URL}/nft/getLikedNfts`,
  LIKETOGGLE: `${API_BASE_URL}/like/toggle`,
  LIKECOUNT: `${API_BASE_URL}/like/getLikesCount`,
  CREATELEAD: `${API_BASE_URL}/leads/newLead`,
  CREATEPAYMENTINTENT:`${API_BASE_URL}/stripe/createPayment`,
  GETBRANDBYSUBDOMAIN:`${API_BASE_URL}/brand/getBrandsBySubdomain`,
};

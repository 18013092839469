import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import TabFilters from "components/TabFilters";
import MarketPlaceAPI from "api/MarketPlaceAPI";
import { useUserData } from "hooks/useUserData";
import { useParams } from "react-router-dom";
import BrandAPI from "api/BrandAPI";
import Loader from "components/Loader";

export interface PageBrandDetailProps {
  className?: string;
  subdomain?: string;
}

const PageBrandDetail: FC<PageBrandDetailProps> = ({ className = "", subdomain }) => {
  const [details, setDetails] = useState<any>({})
    const [lists, setLists] = useState<any>([])
    const [isLoader, setisLoader] = useState(true)

    const { user } = useUserData();

    let { id }:any = useParams();
 
 
    useEffect(() => {
      (async () => {
        let result;
        if (subdomain) {
          // If subdomain is provided, fetch data based on subdomain
          result = await BrandAPI.getBySubdomain(subdomain);
          console.log("result.data", result.data)
          id=result.data._id;
        } else {
          // Otherwise, fetch data based on id
          result = await BrandAPI.getById(id);
        }
        
        const list = await MarketPlaceAPI.getMarket({ brandId: id})
        if (result.data) setDetails(result?.data)
        if (list?.data?.data) setLists(list?.data?.data)
        setisLoader(false)
      })()
    }, [id, subdomain]) // Added subdomain dependency
  
 
    const handleChangeSort = async ({ target }:any) => {
       setisLoader(true)
       const { value } = target;
       if(value === 'recently') {
          const result = await MarketPlaceAPI.getMarket({ brandId: id});
          if (result.data.data) setLists(result.data.data);
          setisLoader(false)
          return;
       }
       if(value === 'endingSoon') {
          const result = await MarketPlaceAPI.getMarket({ brandId: id, filter: ['AUCTION']});
          if (result.data.data) setLists(result.data.data);
          setisLoader(false)
          return;
       }
       const result = await MarketPlaceAPI.getMarket({ brandId: id, filter : value});
       if (result.data.data) setLists(result.data.data);
       setisLoader(false)
    }
 
    const handleChange = async ({target}:any) => {
       setisLoader(true)
       const { value } = target;
       const list = await MarketPlaceAPI.getMarket({ brandId: id, search: value})
       if (list?.data?.data) setLists(list?.data?.data)
       setisLoader(false)
    }

  return (
    <div
      className={`nc-PageBrandDetail  ${className}`}
      data-nc-id="PageBrandDetail"
    >
      <Helmet>
        <title>Yoft || Brand </title>
      </Helmet>

      {/* HEADER */}
      <div className="w-full">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <NcImage
            containerClassName="absolute inset-0"
            src={details?.brandCoverS3URL}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="relative container -mt-14 lg:-mt-20">
          <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row lg:items-center">
            <div className="flex flex-col sm:flex-row md:block sm:items-start sm:justify-between">
              <div className="w-40 sm:w-48 md:w-56 xl:w-60">
                <NcImage
                  src={details?.brandLogoS3URL}
                  containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden"
                />
              </div>
              {/* <div className="mt-4 flex items-center sm:justify-center space-x-3">
                <div className="flex space-x-1.5 text-neutral-700 dark:text-neutral-300">
                  <a
                    href="##"
                    className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                  >
                    <i className="text-base sm:text-xl lab la-facebook-f"></i>
                  </a>
                  <a
                    href="##"
                    className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                  >
                    <i className="text-base sm:text-xl lab la-twitter"></i>
                  </a>
                </div>
                <div className="h-5 border-l border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex space-x-1.5">
                  <ButtonDropDownShare
                    className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer "
                    panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
                  />
                 
                </div>
              </div> */}
            </div>
            <div className="mt-5 md:mt-0 md:ml-8 xl:ml-14 flex-grow">
              {
                isLoader ? <Loader /> :
              <div className="max-w-screen-sm ">
                <h2 className="inline-block text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  {details?.brandName}
                </h2>
                <span className="block mt-4 text-sm text-neutral-500 dark:text-neutral-400">
                  {details?.brandDescription}
                </span>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-20 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          {/* <TabFilters /> */}
          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10  mt-8 lg:mt-10">
            {
                isLoader ? <Loader /> :
                lists.map((_:any, index:any) => (
              <CardNFT key={index} data={_} />
            ))}
          </div>

        </main>

      </div>
    </div>
  );
};

export default PageBrandDetail;
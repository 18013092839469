import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { chainId, chainIdHex, contractAddresses, rpcUrls } from '../config/web3.config'
import nftABI from '../assets/contract/nft.json'
import erc721ABI from '../assets/contract/721.json'

import escrowABI from '../assets/contract/escrow.json'
import { torus } from './torus'

let web3: any = null;
let walletConnectProvider: any = new WalletConnectProvider({
  rpc: {
    [+chainIdHex]: rpcUrls, // Polygon Mainnet rpc url
  },
  chainId: chainId, // Polygon Testnet chainId
  qrcode: false
})

declare module globalThis {
  let web3: any
  let ethereum: any
}

const metamaskConnectInit = () => {
  // Check if Web3 has been injected by the browser (Mist/MetaMask).
  return new Promise((resolve, reject) => {
    if (typeof globalThis.web3 !== 'undefined') {
      // Use Mist/MetaMask's provider.
      web3 = new Web3(globalThis.web3.currentProvider)
      resolve(true)
    }
    else {
      // Handle the case where the user doesn't have web3. Probably
      // show them a message telling them to install Metamask in
      // order to use the app.
      web3 = new Web3(
        new Web3.providers.HttpProvider(
          rpcUrls
        )
      )
      reject(false)
    }
  })
}

const metamaskConnectWithWalletInit = (address: any) => {
  if (address === 'torus') {
    let currentProvider
    if (torus) {
      currentProvider = torus.provider
    }
    if (!torus) {
      currentProvider = localStorage.getItem('currentProvider') ? JSON.parse(localStorage.getItem('currentProvider')||'') : '';
    }
    web3 = new Web3(currentProvider);
    globalThis.web3 = web3;
    localStorage.setItem('currentProvider', JSON.stringify(currentProvider))
  }
  else if (address === 'metamask') {
    web3 = new Web3(
      new Web3.providers.HttpProvider(
        rpcUrls
      )
    )
  }

}

const walletConnectInit = () => {
  // Check if WalleConnect has been conected by the website
  return new Promise((resolve, reject) => {
    if (walletConnectProvider.connector.connected) {
      // Use WalletConnect provider.
      walletConnectProvider.enable()
      web3 = new Web3(walletConnectProvider)
      resolve(true)
    }
    reject(false)
  })
}

const walletConnectModalInit = () => {
  return new Promise((resolve, reject) => {
    walletConnectProvider.enable()
    web3 = new Web3(walletConnectProvider)
    walletConnectProvider.on('disconnect', (code: any, reason: string) => {
      localStorage.removeItem('walletconnect')
    })
    resolve(true)
  })
}

if (!web3) {
  let value: any = localStorage.getItem('walletconnect')
  if (JSON.parse(value)?.connected) {
    localStorage.setItem('isDisconnect', '0')
    walletConnectInit()
  }
  else metamaskConnectInit()
  // if(localStorage.getItem('walletDetails') === 'torus'){
  //   metamaskConnectWithWalletInit('torus')
  // }
}


const getContractInstance = (isEscrow: any) => {
  const { nftContractAddress, escrowContractAddres } = contractAddresses;
  const currentaddress = isEscrow ? escrowContractAddres : nftContractAddress;
  const currentABI = isEscrow ? escrowABI : nftABI;
  try {
    const contractInstance = new web3.eth.Contract(
      currentABI,
      currentaddress
    );
    return contractInstance;
  } catch (error) {
    // console.log(error);
  }
}
const get721ContractInstance = (isEscrow: any) => {
  const { ContractAddress_721 } = contractAddresses;
  const currentaddress =  ContractAddress_721;
  const currentABI = erc721ABI;
  try {
    const contractInstance = new web3.eth.Contract(
      currentABI,
      currentaddress
    );
    return contractInstance;
  } catch (error) {
    // console.log(error);
  }
}
export {
  web3,
  walletConnectProvider,
  walletConnectInit,
  metamaskConnectInit,
  walletConnectModalInit,
  metamaskConnectWithWalletInit,
  getContractInstance,
  get721ContractInstance,
}
import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function PaymentStatus() {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      setMessage("Client secret not found");
      setIsLoading(false);
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setIsLoading(false);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const renderIcon = () => {
    if (isLoading) {
      return <FaSpinner className="payment-icon text-gray-500" />;
    } else if (message === "Payment succeeded!") {
      return <AiOutlineCheckCircle className="payment-icon text-green-500" />;
    } else {
      return <AiOutlineCloseCircle className="payment-icon text-red-500" />;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <IconContext.Provider value={{ size: "48px" }}>
        {renderIcon()}
      </IconContext.Provider>
      <div className="text-lg font-semibold">{message}</div>
      <Link to="/my-subscriptions" className="mt-4">
        <button className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600">
          My Subscriptions
        </button>
      </Link>
    </div>
  );
}

import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import NcModal from "shared/NcModal/NcModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import QrCodeImg from "images/qr-code.png";
import metamaskImg from "images/metamask.webp";
import walletconnectImg from "images/walletconnect.webp";
import walletlinkImg from "images/walletlink.webp";
import fortmaticImg from "images/fortmatic.webp";
import detectEthereumProvider from '@metamask/detect-provider'
import { web3Services } from "api/web3";
import useAuthStore from "store/useAuthStore";
import { web3 } from "utils/web3";
import { _renderLoading } from "shared/Button/Button";
import { toast } from "react-toastify";
import torusImg from "assets/images/torus-logo.png";
import { walletAuthenticate } from "utils/torus";
import UserAPI from "api/UserAPI";
import { useNavigate } from "react-router-dom";
import web3API from "api/web3API";



export interface PageConnectWalletProps {
  className?: string;
}


const PageConnectWallet: FC<PageConnectWalletProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState<any>({
    isMetamask: false,
    isTorus: false,
  });
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate()
  const [usdtBalance, setUSDTBalance] = useState<any>('00.00')
  const { walletAddress, walletConnected, accountBalance, usdtPrice, setWalletConnected, setWalletAddress, setAccountBalance, setUSDTPrice } = useAuthStore()
  const [connectedWallet, setConnectedWallet] = useState(sessionStorage.getItem('walletDetails') || '')

  const queryParams = new URLSearchParams(window.location.search)
  const redirect = queryParams.get("redirect")

  useEffect(() => {
    if (usdtPrice && accountBalance) {
      setUSDTBalance((Number(accountBalance) * Number(usdtPrice)).toFixed(3))
    }
  }, [usdtPrice, accountBalance])

  const fetchUSDTPrice = async () => {
    const string =
      'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd%2Ctry'
    await fetch(string)
      .then((resp) => resp.json())
      .then(async (data) => {
        setUSDTPrice(data['matic-network'].usd)
      });
  }

  const getBalance = async (account: string) => {
    let balance: any = Number(
      web3.utils.fromWei(await web3.eth.getBalance(account))
    );
    balance = balance.toLocaleString(undefined, 2);
    setAccountBalance(balance)
  }

  const walletClick = async () => {
    setLoading({ isTorus: true })
    const result = await walletAuthenticate();
    if (result) {
      sessionStorage.setItem('walletDetails', 'torus')
      sessionStorage.setItem('userAddress', result.walletAddress);
      setWalletAddress(result.walletAddress)
      await UserAPI.updateUser(result);
      setLoading({ isTorus: false })
      setConnectedWallet('torus')
      toast.success('Wallet Connected Successfully');
      if (redirect) {
        navigate(redirect)
      }
    }
  }

  const handleClickWallet = async () => {
    try {

      setLoading({ isMetamask: true })
      let provider = await detectEthereumProvider()
      if (provider) {
        let data = await web3Services.enableMetamask();

        if (data && data.accounts.length) {
          const nonceData = await web3API.generateNonce(data.accounts[0])
          console.log(nonceData)
          const signature = await web3.eth.personal.sign(
            web3.utils.utf8ToHex(nonceData.data.data.nonce),
            data.accounts[0]
          )
          console.log(signature)
          let result = await UserAPI.updateUser({
            walletAddress: data.accounts[0],
            walletType: 'metamask',
            walletDetails: {}
          })
          if (result.data.data.invalid) {
            throw new Error(result.data.data.message)
          }else{
            await web3API.authenticateWallet(nonceData.data.data.nonce, signature)
          }

          setWalletAddress(data.accounts[0])
          sessionStorage.setItem('userAddress', data.accounts[0])
          getBalance(data.accounts[0])
          fetchUSDTPrice()
        }
        setLoading({ isMetamask: false })
        sessionStorage.setItem('walletDetails', 'metamask')
        setConnectedWallet('metamask')
        toast.success('Wallet Connected Successfully');
        if (redirect) {
          navigate(redirect)
        }
        // await props.enableMetamask()
        // setClicked(true)
        // setShowWallet(false)
        // setIsConnect(false)
      } else {
        setLoading({ isMetamask: false })
        toast.error('Please install MetaMask.');

        // console.error('Please install MetaMask.');
        // props.onClose()
      }
    } catch (error: any) {
      console.log(error)
      setLoading({ isMetamask: false, isTorus: false })

      toast.error(error.message)
    }

  }
  const plans = [
    {
      name: "Metamask",
      key: 'isMetamask',
      img: metamaskImg,
      callBackFunction: (() => handleClickWallet())
    },
    {
      name: "Torus",
      key: 'isTorus',
      img: torusImg,
      callBackFunction: (() => walletClick())
    },
  ];
  const renderContent = () => {
    return (
      <form action="#">
        <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          Scan to connect
        </h3>
        <span className="text-sm">
          Open Coinbase Wallet on your mobile phone and scan
        </span>

        <div className="p-5 border bg-white dark:bg-neutral-300 border-neutral-200 dark:border-neutral-700 rounded-xl flex items-center justify-center mt-4">
          <NcImage className="w-40" src={QrCodeImg} />
        </div>

        <div className="mt-5 space-x-3">
          <ButtonPrimary type="submit">Install app</ButtonPrimary>
          <ButtonSecondary type="button">Cancel</ButtonSecondary>
        </div>
      </form>
    );
  };

  return (
    <div
      className={`nc-PageConnectWallet ${className}`}
      data-nc-id="PageConnectWallet"
    >
      <Helmet>
        <title>Yoft | Connect Wallet</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Connect your wallet.
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              Connect with our available wallet providers
              {/* or create a new              one. */}
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
            <div className="space-y-3">
              {plans.map((plan) => (
                <div
                  key={plan.name}
                  onClick={() => !walletAddress && plan.callBackFunction()}
                  typeof="button"
                  tabIndex={0}
                  className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border 
                border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex 
                focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 
                dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200 align-center"
                >
                  <div className="flex items-center w-full">
                    <NcImage
                      src={plan.img}
                      containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
                    />
                    <div
                      className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
                    >
                      {plan.name}
                    </div>
                    <div className={`ml-auto `}>{loading[plan.key] && _renderLoading()} {walletAddress &&  (connectedWallet==plan.name.toLowerCase())&& 'Wallet Connected'}</div>
                  </div>
                </div>
              ))}
            </div>

            {/* ---- */}
            <div className="pt-2 ">
              <ButtonPrimary href={"/"} className="flex-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5 12H3.67004"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="ml-2">Go Back Home</span>
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>

      <NcModal
        renderTrigger={() => null}
        isOpenProp={showModal}
        renderContent={renderContent}
        contentExtraClass="max-w-md"
        onCloseModal={() => setShowModal(false)}
        modalTitle="Connect Wallet"
      />
    </div>
  );
};

export default PageConnectWallet;

import { useEffect } from "react";


export const catchErrorMessage = (e: any) =>
  e.response
    ? e.response.data.message
    : e.message;

export const getAdminRoutePath = (path: string) => {
  return path;
  // .replace(ADMIN, '');
};
export const getAuthRoutePath = (path: string) => {
  return path.replace('', '');
};

export const passwordRegex =
  /* eslint-disable-next-line */
  /^(?=.*[0-9])(?=.*[-?!@|>'"<\[\]()£=#$%^;:+_&%&@±§.,*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@|>'"<\[\]()£=#$%^;:+_&%&@±§.,*\/\\]{6,20}$/;


export function getNFTTime(timeline=new Date(), history = false) {
  let data
  if (!history) {
    data = new Intl.DateTimeFormat('en-US',
      {
        year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
        // second: '2-digit' 
      }).format(timeline)
  } else {
    data = new Intl.DateTimeFormat('en-US',
      {
        year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
        // second: '2-digit' 
      }).format(new Date(timeline))
  }
  return data
}

export const getCompactAddress = (address:string) => {
  const compactAddress = address
    ? address.substring(0, 5) +
    '....' +
    address.substring(address.length - 5, address.length)
    : '00000000000'
  return compactAddress;
}



export const useOutsideClick = (ref:any, callback:CallableFunction) => {
  const handleClick = (e:Event) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};


import React, { FC, useEffect } from "react";
import MainNav2Logged from "./MainNav2Logged";

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  return (
    <div id="sticky" className="nc-HeaderLogged relative w-full z-40 nav-wrapper">
      {/* NAV */}
      <MainNav2Logged />
    </div>
  );
};

export default HeaderLogged;

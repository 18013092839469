import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebook from "images/socials/facebook.svg";
import twitter from "images/socials/twitter.svg";
import telegram from "images/socials/instagram.png";
import youtube from "images/socials/web.png";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
  data?: any;
}

const socialsDemo: SocialType[] = [
  // { name: "Facebook", key:"twitter", icon: facebook, href: "#" },
  { name: "Twitter", key:"twitter", icon: twitter, href: "https://twitter.com/share?url=" },
  { name: "Website", key:"website", icon: youtube, href: "//" },
  { name: "Instagarm", key:"instagarm",  icon: telegram, href: "https://telegram.me/share/url?url=" },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block w-6 h-6",
  socials = socialsDemo,
  data = {}
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item: any, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href+data[item.key]?.url}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <img src={item.icon} alt="" />
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;

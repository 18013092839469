import dercABI from '../contracts/abis/derc.json'
import { web3 } from './web3';

export function getTokenContractInstance(token: any) {
    let currentaddress
    let currentABI
    if(token=='DERC20'){
      currentaddress = '0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1';
      currentABI = dercABI;
    }
    else if(token=='USDC'){
      currentaddress = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';
      currentABI = dercABI; //do not change this. 
    }
    try {
      // if(torusWeb3 && walletDetails === 'torus') {
      //   const contractInstance = new torusWeb3.eth.Contract(
      //     currentABI,
      //     currentaddress
      //   );
      //   return contractInstance;
      // }
      // if (web3 && walletDetails === 'metaMask') {
      const contractInstance = new web3.eth.Contract(
        currentABI,
        currentaddress
      );
      return contractInstance;
      // }
    } catch (error) {
      // console.log(error);
    }
  }
import React, { FC } from "react";
import CardCategoryList from "components/CardCategory5/CardCategoryList";
import Loader from "components/Loader";

export interface CardListProps {
  className?: string;
  heading?: string;
  subHeading?: string;
  data?: any[];
  isLoader?: boolean;
}

const CardList: FC<CardListProps> = ({ className = "", heading = "", subHeading = "", data = [], isLoader = true }) => {
  return (
    <div className={`nc-CardList ${className}`}>
  <h2 className="text-2xl font-bold mb-2">{heading}</h2>
  <p className="text-gray-500 mb-4">{subHeading}</p>
  {isLoader ? (
    <Loader />
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-16">
      {data?.map((item: any, index: number) => (
        <div key={index} className="flex items-center justify-center">
          <CardCategoryList
            to={`/brands/${item._id}`}
            index={index}
            featuredImage={item?.brandLogoS3URL}
            name={item?.brandName}
            subName={item?.subName}
            imgFit="object-fill"
          />
        </div>
      ))}
    </div>
  )}
</div>

  );
};

export default CardList;

import { Helmet } from "react-helmet";


const PrivacyPolicy = () => {
    return (
        <div
            className={` relative `}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>


            <div className="container py-16 lg:py-16 space-y-2 lg:space-y-4">

                <h1 className="pb-6">Privacy Policy</h1>

                <p className="pb-4 mt-2 text-sm">This is the Privacy and Cookies Policy for FanAnywhere ("we", "us" and "our site") (together, the "Platform"). The Platform is operated by or on behalf of FanAnywhere LLC trading as FanAnywhere. We are committed to protecting and respecting your privacy.</p>

                <p className="pb-4 mt-2 text-sm"> Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By using the Platform you acknowledge you have read and understood this privacy policy. For the purposes of European Economic Area data protection law, (the "Data Protection Law"), the data controller is: FanAnywhere LLC.
                </p>
                <h2 className="font-extrabold text-lg md:text-2xl">
                    Information we collect about you </h2>

                <p className="pb-4 mt-2 text-sm">
                    We will collect and process the following personal data from you:
                </p>

                <p className="pb-4 mt-2 text-sm">Information you give us </p>

                <p className="pb-4 mt-2 text-sm">
                    This is information about you that you give us directly when you interact with us and includes your username, and your email address or Twitter handle if you choose to give us this information.
                </p>
                <p className="pb-4 mt-2 text-sm">Information we collect about you from your use of the Platform: </p>

                <p className="pb-4 mt-2 text-sm">We will automatically collect information from you each time you visit our site.</p>
                <p className="pb-4 mt-2 text-sm">
                This includes:
                Technical information, which may include the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system, and platform;
                Information about your visit, which may include the full Uniform Resource Locators (URL), clickstream to, through, and from our site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page.
                </p>
                <h2 className="font-extrabold text-lg md:text-2xl">
                    Cookies and other technologies</h2>
                <p className="pb-4 mt-2 text-sm">The Platform uses cookies and similar technologies to distinguish you from other users of the Platform.
                    Most web browsers automatically accept cookies and similar technologies, but if you prefer, you can change your browser to prevent that and your help screen or manual will tell you how to do this. We also give you information about how to disable cookies in the table. However, you may not be able to take full advantage of our website if you do so.
                    A number of cookies and similar technologies we use last only for the duration of your web or app session and expire when you close your browser. Others are used to remember you when you return to the Platform and will last for longer.
                    We use these cookies and other technologies on the basis that they are necessary for the performance of a contract with you, or because using them is in our legitimate interests (where we have considered that these are not overridden by your rights), and, in some cases, where required by law, where you have consented to their use.
                </p>
                <h2 className="font-extrabold text-lg md:text-2xl">
                    We use the following types of cookies:</h2>
                <p className="pb-4 mt-2 text-sm">
                    Strictly necessary cookies. These are cookies that are required for the operation of our website and under our terms with you. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or, make use of e-billing services.
                    Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it. This helps us for our legitimate interests of improving the way our website works, for example, by ensuring that users are finding what they are looking for easily.
                    Functionality cookies. These are used to recognize you when you return to our website. This enables us, subject to your choices and preferences, to personalize our content, greet you by name and remember your preferences (for example, your choice of language or region)
                </p>
                <h2 className="font-extrabold text-lg md:text-2xl">
                    Contact Us</h2>
                <p className="pb-4 mt-2 text-sm">
                    Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to: contact@yoft.xyz
                </p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
import React, { FC, useEffect, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import Loader from "components/Loader";

export interface SectionSliderBrandsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: any;
  isLoader?: boolean;
}


const SectionSliderBrands: FC<SectionSliderBrandsProps> = ({
  heading = "Browse by Brands",
  subHeading = "Explore the tradable subscriptions from top brands.",
  className = "",
  itemClassName = "",
  data = [],
  isLoader = true
}) => {
  const sliderRef = useRef(null);
  const id = new Date().valueOf().toString();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  console.log(data)

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4,
        },
        1024: {
          gap: 20,
          perView: 3.4,
        },
        768: {
          gap: 20,
          perView: 3,
        },
        640: {
          gap: 20,
          perView: 2.3,
        },
        500: {
          gap: 20,
          perView: 1.4,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS, data]);
  return (
    <div className={`nc-SectionSliderBrands ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading desc={subHeading} hasNextPrev>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {isLoader ? <Loader /> : data?.map((item: any, index:number) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <CardCategory5
                  to={`/brands/${item._id}`}
                  index={index}
                  featuredImage={item?.brandLogoS3URL}
                  name={item?.brandName}
                  subName={item?.subName}
                  imgFit="object-fill"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderBrands;

import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { catchErrorMessage, passwordRegex } from "utils/helper";
import UserAPI from "api/UserAPI";
import { toast } from "react-toastify";

export interface PageSignUpProps {
  className?: string;
}

export const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  email: Yup.string().email('Enter a valid email').required('Required field'),
  password: Yup.string()
    .required('Required field')
    .min(6, 'Password must contain at least 6 characters')
    .max(20, 'Password must not be longer than 20 characters')
    .matches(
      passwordRegex,
      'Use a minimum length of 6 characters. Use a maximum length of 20 characters. Use a mix of alphabets and' +
      ' numbers. Use at least one uppercase character. Use a special character.',
    )
    .nullable(),
});

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: ''
    },
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      if (Object.keys(errors).length > 0) {
        return;
      }
      let body = { ...data };
      await UserAPI.signUp(body);
      setLoading(false);
      setSuccess(true);
      toast.success('We have sent a verification mail. Please verify your account');
    } catch (e) {
      setLoading(false);
      toast.error(catchErrorMessage(e));
    }
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Yoft || Sign up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">

          {!success ? (
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  First Name
                </span>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input type="text" className="validate mt-1"  {...field} />
                  )}
                  name="firstName"
                  defaultValue=""
                />
                <p className='input-error'>{errors?.firstName?.message}</p>
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Last Name
                </span>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input type="text" className="validate mt-1"  {...field} />
                  )}
                  name="lastName"
                  defaultValue=""
                />
                <p className='input-error'>{errors?.lastName?.message}</p>
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input type="email" className="validate mt-1"  {...field} />
                  )}
                  name="email"
                  defaultValue=""
                />
                <p className='input-error'>{errors?.email?.message}</p>
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                </span>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input type="password" className="validate mt-1"  {...field} />
                  )}
                  name="password"
                  defaultValue=""
                />
                <p className='input-error'>{errors?.password?.message}</p>
              </label>
              <ButtonPrimary loading={loading} type="submit">Continue</ButtonPrimary>
            </form>
          ) : (
            <div className="success-message">We have sent a verification mail. Please verify your account.</div>
          )}

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login" className="text-green-600">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;

import { useQuery, useQueryClient } from 'react-query';
import { useCallback, useMemo } from 'react';
import useAuthStore from '../store/useAuthStore';
import shallow from 'zustand/shallow';
import { API_URLS } from '../constants/endpoints';
import UserAPI from '../api/UserAPI';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';


export const useUserData = () => {
  const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [accessToken, resetState, userDetails] = useAuthStore(
      (state) => [state.accessToken, state.resetState, state.user],
      shallow,
    );

    const user = useMemo(() => (accessToken ? userDetails : null), [userDetails, accessToken]);
  
    const logout = useCallback(() => {
      resetState();
      queryClient.removeQueries();
      navigate('/')
    }, [queryClient, resetState]);
  
    return {
      accessToken,
      user,
      userDetails: !userDetails ? { isLoader: false } : userDetails,
      logout,
    };
  };
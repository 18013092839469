import React, { useMemo } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import NftDetailPage from "containers/NftDetailPage/NftDetailPage";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageUploadItem from "containers/PageUploadItem";
import PageConnectWallet from "containers/PageConnectWallet";
import PageHome2 from "containers/PageHome/PageHome2";
// import PageHome3 from "containers/PageHome/PageHome3";
import PageBrand from "containers/Brands/Brand";
import PageForgotPassword from "containers/PageForgotPassword/PageForgotPassword";
import PageBrandDetail from "containers/Brands/BrandDetail";
import JoinUs from "containers/JoinUs/JoinUs";
import TorusWalletAuthorize from "containers/TorusAuthorize";
import TermsAndCondition from "containers/pageDocs/termsAndCondition";
import PrivacyPolicy from "containers/pageDocs/privacyPolicy";
import CookieConsent from "react-cookie-consent";
import StripePayment from "containers/stripe/stripePayment";

/* export const pages: Page[] = [
  { path: "/", component: () => <PageHome2 subDomain={subDomain} /> },
{ path: "/#", component: () => <PageHome2 subDomain={subDomain} /> },

  { path: "/home2", component: PageHome },
  // { path: "/document", component: PageHome3 },
  { path: "/home-header-2", component: PageHome },
  { path: "/subscription-detail/:id", component: NftDetailPage },
  { path: "/subscription-detail", component: NftDetailPage }, 
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  { path: "/my-subscriptions", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/page-upload-item", component: PageUploadItem },
  { path: "/connect-wallet", component: PageConnectWallet },
  { path: "/blog", component: BlogPage },
  { path: "/blog/:id", component: BlogSingle },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/forgot-pass", component: PageForgotPassword },
  { path: "/subscription", component: PageSubcription },
  { path: "/brands", component: PageBrand },
  { path: "/brands/:id", component: PageBrandDetail },
  { path: "/join-us", component: JoinUs },
  { path: "/auth", component: TorusWalletAuthorize },
  { path: "/terms-and-conditions", component: TermsAndCondition },
  { path: "/payment", component: StripePayment },
  { path: "/privacy-policy", component: PrivacyPolicy },

]; */

let authPath = ["/login", "/signup", "/forgot-pass"];
const MyRoutes = () => {
  let location = window.location;
  let subDomain = window.location.hostname.split(".")[0];

  const pathMemo = useMemo(() => {
    return authPath.includes(location.pathname) ? true : false;
  }, [location]);

  const pages: Page[] = [
    { path: "/", component: () => <PageHome2 subDomain={subDomain} /> },
    { path: "/#", component: () => <PageHome2 subDomain={subDomain} /> },

    { path: "/home2", component: PageHome },
    // { path: "/document", component: PageHome3 },
    { path: "/home-header-2", component: PageHome },
    { path: "/subscription-detail/:id", component: NftDetailPage },
    { path: "/subscription-detail", component: NftDetailPage },
    { path: "/page-collection", component: PageCollection },
    { path: "/page-search", component: PageSearch },
    { path: "/my-subscriptions", component: AuthorPage },
    { path: "/account", component: AccountPage },
    { path: "/page-upload-item", component: PageUploadItem },
    { path: "/connect-wallet", component: PageConnectWallet },
    { path: "/blog", component: BlogPage },
    { path: "/blog/:id", component: BlogSingle },
    { path: "/contact", component: PageContact },
    { path: "/about", component: PageAbout },
    { path: "/signup", component: PageSignUp },
    { path: "/login", component: PageLogin },
    { path: "/forgot-pass", component: PageForgotPassword },
    { path: "/subscription", component: PageSubcription },
    { path: "/brands", component: PageBrand },
    { path: "/brands/:id", component: PageBrandDetail },
    { path: "/join-us", component: JoinUs },
    { path: "/auth", component: TorusWalletAuthorize },
    { path: "/terms-and-conditions", component: TermsAndCondition },
    { path: "/payment", component: StripePayment },
    { path: "/privacy-policy", component: PrivacyPolicy },
  ];
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <SiteHeader subDomain={subDomain} />
        <div className="homeLayout">
          <Routes>
            {pages.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}
            <Route element={<Page404 />} />
          </Routes>
        </div>
        {!pathMemo && <Footer subDomain={subDomain}/>}
      </BrowserRouter>
      <CookieConsent
        location="bottom"
        cookieName="myAwesomeCookieName3"
        expires={999}
      >
        We use cookies to ensure that we give you the best experience on our
        website.
      </CookieConsent>
    </>
  );
};

export default MyRoutes;

import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import SocialsShare from "shared/SocialsShare/SocialsShare";
import { imgHigtQualitys } from "contains/fakeData";

export interface CardBlogHomeProps {
  className?: string;
  data?: any;
}

const CardBlogHome: FC<CardBlogHomeProps> = ({
  data,
  className = "h-full",
}) => {
  return (
    <div
      className={`nc-CardBlogHome group relative flex flex-col ${className}`}
      data-nc-id="CardBlogHome"
    >
      <Link
        to={{
          pathname: `/blog/${data.title}`,
        }}
        state={{
          id: data._id,
        }}
        className="block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden"
      >
        <NcImage
          src={data.image_url || imgHigtQualitys[0]}
          containerClassName="absolute inset-0"
          alt={"title"}
        />
        <div className="absolute inset-0 flex items-center justify-center bg-neutral-900 bg-opacity-50 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
        </div> 
      </Link>

      <div className=" mt-8 pr-10 flex flex-col">
        <h2
          className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl`}
        >
          <Link
            to={{
              pathname: `/blog/${data.title}`,
            }}
            state={{
              id: data._id,
            }}
            className="line-clamp-2 capitalize"
            title={"title"}
          >
            {data.title}
          </Link>
        </h2>
        <span className="hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400">
          <span className="line-clamp-2">{data.description}</span>
        </span>
      </div>
    </div>
  );
};

export default CardBlogHome;

import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header2 from "components/Header/Header2";
import Header2Brand from "components/Header/Header2Brand"; // Import the Header2Brand component
import HeaderLoggedBrand from "components/Header/HeaderLoggedBrand"; // Import the HeaderLoggedBrand component
import { useUserData } from "hooks/useUserData";
import { FaReact } from 'react-icons/fa';
interface SiteHeaderProps {
  subDomain: string;
}
const SiteHeader: React.FC<SiteHeaderProps> = ({ subDomain }) => {
  let location = useLocation();
  const { accessToken } = useUserData();
  
  useEffect(() => {
    const onResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const scroller = () => {
      let sticky = document.getElementById('sticky');
      let scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll >= 25) sticky?.classList.add('fixed');
      else sticky?.classList.remove("fixed");
    }

    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', scroller);
    onResize();
    return () => {
      window.removeEventListener('scroll', scroller);
      window.removeEventListener('resize', onResize);
    };
  }, []);
  
  const userLogged = useMemo(() => { return !!accessToken }, [accessToken]);

  // If subDomain is "www", show Header2 and HeaderLogged; otherwise, show Header2Brand and HeaderLoggedBrand
  const HeaderComponent = subDomain === "www" ? Header2 : Header2Brand;
  const HeaderLoggedComponent = subDomain === "www" ? HeaderLogged : HeaderLoggedBrand;

  return (
    <>
      {/* <div className="flex items-center justify-center bg-orange-500 text-white p-2" style={{ fontFamily: "Roboto, sans-serif" }}>
        <FaReact className="mr-2" />
        Subsummit attendees get 50% discount on listing fee. <a href="https://form.jotform.com/231481258833459" target="_blank" className="underline text-white px-2">Fill your details.</a>
      </div> */}
      {!userLogged ? <HeaderComponent /> : <HeaderLoggedComponent />}
    </>
  );
};

export default SiteHeader;

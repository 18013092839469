import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export default function CustomIframe({ src, modalOpen, handleClose }: any) {
    return (
        <>
        {
            modalOpen && <div className="relative z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          
            <div className="fixed inset-0 z-[999] overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <iframe src={src} title="Pay | Fananywhere" width="99%" height="550" allow="camera *;microphone *" />
                <ButtonClose className="fixed top-[2px] right-[2px]" onClick={handleClose}></ButtonClose>
                </div>
              </div>
            </div>
          </div>
          
        }
        </>
    );
}
import apiClient from '../config/apiClient';
import useAuthStore from '../store/useAuthStore';

class APIAbstract {
    protected getAuthCredentials() {
        return { headers: { "x-auth-token": `${this.getToken()}` } };
    }

    public get(url: string, params = {}): Promise<any> {
        return apiClient.get(`${url}`, { ...this.getAuthCredentials(), ...params });
    }

    public post(url: string, data?: unknown, params = {}): Promise<any> {
        return apiClient.post(`${url}`, data, { ...this.getAuthCredentials(), ...params });
    }

    public put(url: string, data?: unknown, params = {}): Promise<any> {
        return apiClient.put(`${url}`, data, { ...this.getAuthCredentials(), ...params });
    }

    public delete(url: string, params = {}): Promise<any> {
        return apiClient.delete(`${url}`, { ...this.getAuthCredentials(), ...params });
    }

    protected getToken() {
        return useAuthStore.getState().accessToken;
    }
}

export default APIAbstract;

import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import HandsImg from "../../assets/images/join-team-image.png"
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionOpenPositionProps {
    className?: string;
}

const SectionOpenPosition: FC<SectionOpenPositionProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-SectionOpenPosition relative flex flex-col lg:flex-row lg:items-center ${className}`}
            data-nc-id="SectionOpenPosition"
        >

            <div className="w-[60%]">
                <NcImage src={HandsImg} />
            </div>

            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:w-[40%]">
                <span className="block mt-5 text-neutral-500 dark:text-neutral-100">
                    Love what we do?
                </span>
                <h2 className="font-semibold text-4xl">Join Our Team!</h2>

                <ButtonPrimary
                    type="submit"
                    className="mt-8"
                >
                    <a href="/join-us#our-position">
                        Explore Open Positions
                    </a>
                </ButtonPrimary>
            </div>

        </div>
    );
};

export default SectionOpenPosition;

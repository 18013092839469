// import TorusSdk, { TorusLoginResponse } from "@toruslabs/customauth";
import Torus, { TorusInpageProvider } from "@toruslabs/torus-embed";

import { currency_symbol, chainId, network_name, torus_host } from "../config/web3.config";
import { metamaskConnectWithWalletInit, web3 } from "./web3";

let torus: any;
let torusWeb3 = null;
// GOOGLE_VERIFIER="google"
// FACEBOOK_VERIFIER="facebook"
// TWITTER_VERIFIER="torus-auth0-twitter"
const HOSTED_EMAIL_PASSWORDLESS_VERIFIER = "torus-auth0-email-passwordless"
async function walletAuthenticate() {
    torus = new Torus({});
    await torus.init({
        enableLogging: false,
        showTorusButton: true,
        loginConfig: {
            google: {
                showOnModal: true, // whether to show or not
                mainOption: true, // big button
                showOnDesktop: true, // desktop only
                showOnMobile: true, // mobile only
              },
            [HOSTED_EMAIL_PASSWORDLESS_VERIFIER]: {
                showOnModal: true,
                mainOption: false,
                showOnDesktop: true,
                showOnMobile: true,
            },
            facebook: {
                showOnModal: false,
                mainOption: false,
                showOnDesktop: false,
                showOnMobile: false,
            }
          },
        network: {
            host: torus_host, // mandatory
            chainId: chainId, // optional
            networkName: network_name // optional
          },
    });
    await torus.login({
        verifier: '',
    });
    const userInfo = await torus.getUserInfo();
    metamaskConnectWithWalletInit('torus')
    const address = (await web3.eth.getAccounts())[0];
    // const balance = await web3.eth.getBalance(address);
    return {
        walletAddress: address,
        walletType: 'torus',
        walletDetails: userInfo
    };
}
const logout = async () => {
    if (torus) {
        await torus.logout()
    }
}



export {
    torus,
    walletAuthenticate,
    logout,
    torusWeb3
}
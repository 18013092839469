import { API_URLS } from '../constants/endpoints';
import APIAbstract from './APIAbstract';

class BlogAPI extends APIAbstract {

  public getBlogs() {
    return this.get(API_URLS.BLOG);
  }

  public getById(id:string) {
    return this.get(`${API_URLS.BLOG}/${id}`);
  }

}

export default new BlogAPI();
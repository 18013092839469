import { API_URLS } from '../constants/endpoints';
import APIAbstract from './APIAbstract';

class MarketAPI extends APIAbstract {

  public getMarket(payload = {}) {
    return this.post(`${API_URLS.MARKETPLACE}?isBrand=true`, payload);
  }

  public getById(id:any) {
    return this.get(`${API_URLS.NFTDETAILS}/${id}`);
  }

  public gethistoryId(id:any) {
    return this.get(`${API_URLS.NFTHISTORY}/${id}`);
  }
  
  public mintNft(payload = {}) {
    return this.post(API_URLS.ADD_NFT, payload);
  }

  public getLikedNfts(id:any) {
    return this.get(`${API_URLS.GETLINKEDNFT}/${id}`);
  }

  public updateEditionOnBuy(nftId:any) {
    return this.post(`${API_URLS.UPDATEEDITION}`, { nftId });
  }

  public collectedNft(id:any) {
    return this.get(`${API_URLS.COLLECTEDNFT}/${id}?isBrand=true`);
  }

  public getLikedNFT(id:any) {
    return this.get(`${API_URLS.LIKEDEDNFT}/${id}?isBrand=true`);
  }

  public likeToggler(id:any) {
    return this.get(`${API_URLS.LIKETOGGLE}/${id}`);
  }

  public getLikesCount(id:any) {
    return this.get(`${API_URLS.LIKECOUNT}/${id}`);
  }
}

export default new MarketAPI();
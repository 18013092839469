import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import images1 from "images/nfts/cat1.webp";

export interface CCardCategoryListProps {
  className?: string;
  featuredImage?: string;
  name?: string;
  subName?: string;
  index?: number;
  to?: string;
  imgFit?: string;
}

const COLORS = [
  "bg-pink-500",
  "bg-blue-500",
  "bg-green-500",
  "bg-red-500",
  "bg-yellow-500",
  "bg-purple-500",
  "bg-orange-500",
  "bg-indigo-500",
  "bg-teal-500",
  "bg-gray-500",
];

const CardCategoryList: FC<CCardCategoryListProps> = ({
  className = "",
  featuredImage = images1,
  name,
  subName,
  index,
  to = "",
  imgFit = "object-cover",
}) => {
  return (
    <>
      {to ? (
        <Link
          to={to}
          className={`nc-CardCategory5 flex flex-col ${className}`}
          data-nc-id="CardCategory5"
        >
          <div className="w-64 h-64 relative rounded-lg overflow-hidden">
            <div className="aspect-w-1 aspect-h-1">
              <NcImage
                src={featuredImage}
                className={`${imgFit} w-full h-full object-center object-cover`}
              />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            {/* <div className={`w-10 h-10 rounded-full ${COLORS[index]}`}></div> */}
            <div className="ml-3">
              <h2 className="text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate">
                {name}
              </h2>
              <span className="block mt-1 text-sm text-neutral-6000 dark:text-neutral-400">
                {subName}
              </span>
            </div>
          </div>
        </Link>
      ) : (
        <>
          <div className="w-64 h-64 relative rounded-lg overflow-hidden">
            <div className="aspect-w-1 aspect-h-1">
              <NcImage
                src={featuredImage}
                className={`${imgFit} w-full h-full object-center object-cover`}
              />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            {/* <div className={`w-10 h-10 rounded-full ${COLORS[index]}`}></div> */}
            <div className="ml-3">
              <h2 className="text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate">
                {name}
              </h2>
              <span className="block mt-1 text-sm text-neutral-6000 dark:text-neutral-400">
                {subName}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CardCategoryList;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import PaymentStatus from "./paymentStatus";
import "./stripe.css";
import StripeAPI from "api/stripeAPI";
import { AiOutlineCalendar, AiOutlineDollarCircle ,AiOutlineUser } from "react-icons/ai";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";
import { stripe_public_key } from "../../config/web3.config";

/* const stripePromise = loadStripe(
  "pk_test_51MWeKPBJJpUSfFeMqqMoXbdksb1Q1I7TXlRxZauVQHPtnOWXA3TaNA7nCKKHx3tCjlQqwASNVhYacAURsxM9V6GM00aJykS25N"
); */
const stripePromise = loadStripe(stripe_public_key);

export default function StripePayment() {
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState("");
  const [itemDetails, setItemDetails] = useState({
    title: "",
    description: "",
    image: "",
    price: "",
    currency: "",
    brandname:"",
    validity:"",
  });
  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      const paymentSecret = params.get("payment_intent_client_secret");
      const id = params.get("id");


      if (paymentSecret) {
        setPaymentIntentClientSecret(paymentSecret);
      } else {
        const result = await StripeAPI.createPaymentIntent({
          items: [{ id: id }],
        });
        setClientSecret(result.data.clientSecret);
        setItemDetails(result.data.item_details);
      }
    };

    fetchData();
  }, [location]);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#555555',
      colorText: '#ffffff',

    }  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div id="root" className="stripe-page">
      <div className="flex justify-center items-center h-screen">
      {paymentIntentClientSecret ? (
          <Elements stripe={stripePromise}>
            <PaymentStatus />
          </Elements>
        ) : (
          clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <div className="text-center">
                <h2 className="text-2xl font-bold mb-4">Checkout Summary</h2>
                <hr className="border-b-2 border-gray-300 mb-4" />
                <div className="mx-auto max-w-md">
                  <div className="flex items-center justify-center mb-4">
                    <div className="bg-green-500 rounded-full p-2 mr-2">
                      <AiOutlineUser size={20} className="text-white" />
                    </div>
                    <div>
                      <p className="font-bold">Membership: {itemDetails.title}</p>
                      <p className="text-sm">Offered by: {itemDetails.brandname}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center mb-4">
                    <div className="bg-blue-500 rounded-full p-2 mr-2">
                      <AiOutlineCalendar size={20} className="text-white" />
                    </div>
                    <p className="font-bold">Validity: 1 year</p>
                  </div>
                  <div className="flex items-center justify-center mb-4">
                    <div className="bg-yellow-500 rounded-full p-2 mr-2">
                      <AiOutlineDollarCircle size={20} className="text-white" />
                    </div>
                    <p className="font-bold">Price: {itemDetails.price} {itemDetails.currency}</p>
                  </div>
                </div>
                <hr className="border-b-2 border-gray-300 my-4" />
                <CheckoutForm />
              </div>
            </Elements>
          )
        )}
      </div>
    </div>
  );
}

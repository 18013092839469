import { API_URLS } from '../constants/endpoints';
import APIAbstract from './APIAbstract';

class BrandAPI extends APIAbstract {

  public create(payload:any) {
    return this.post(API_URLS.CREATEBRAND, payload);
  }

  public getBrand() {
    return this.get(API_URLS.CREATEBRAND);
  }

  public getById(id:string) {
    return this.get(`${API_URLS.CREATEBRAND}/${id}`);
  }

  public getBySubdomain(subdomain:string) {
    return this.get(`${API_URLS.GETBRANDBYSUBDOMAIN}/${subdomain}`);
  }

  public getByUserId(id:string) {
    return this.get(`${API_URLS.GETUSERBRAND}/${id}`);
  }

}

export default new BrandAPI();
import React, { FC } from "react";
import MainNav2Brand from "./MainNav2Brand";

export interface Header2Props {}

const Header2: FC<Header2Props> = () => {
  return (
    <div id="sticky" className="nc-Header2 relative w-full z-40 nav-wrapper ">
      <MainNav2Brand />
    </div>
  );
};

export default Header2;

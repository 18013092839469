import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Demo 1",
  },
  {
    id: ncNanoId(),
    href: "/home2",
    name: "Home Demo 2",
  },
  {
    id: ncNanoId(),
    href: "/document",
    name: "Home Demo 3",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Collection page",
  },
  {
    id: ncNanoId(),
    href: "/my-subscriptions",
    name: "My profile",
  },
  {
    id: ncNanoId(),
    href: "/subscription-detail",
    name: "Subscription details",
  },
  {
    id: ncNanoId(),
    href: "/account",
    name: "Account settings",
  },
  {
    id: ncNanoId(),
    href: "/page-upload-item",
    name: "Upload Item",
  },
 /*  {
    id: ncNanoId(),
    href: "/connect-wallet",
    name: "Connect Wallet",
  }, */

  {
    id: ncNanoId(),
    href: "/about",
    name: "Other Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/about",
        name: "About",
      },
      {
        id: ncNanoId(),
        href: "/contact",
        name: "Contact us",
      },
      {
        id: ncNanoId(),
        href: "/login",
        name: "Login",
      },
      {
        id: ncNanoId(),
        href: "/signup",
        name: "Signup",
      },
      {
        id: ncNanoId(),
        href: "/subscription",
        name: "Subscription",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/blog",
        name: "Blog Page",
      },
    ],
  },
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
  // {
  //   id: ncNanoId(),
  //   href: "/",
  //   name: "Discover",
  //   type: "dropdown",
  //   children: otherPageChildMenus,
  // },
  {
    id: ncNanoId(),
    href: "/brands",
    name: "Brands",
   },
   {
    id: ncNanoId(),
    href: "/about",
    name: "About Us",
   },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blogs",
   }
        
];

import Label from "components/Label/Label";
import React, { FC, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import FormItem from "components/FormItem";
import { RadioGroup } from "@headlessui/react";
import { nftsImgs } from "contains/fakeData";
import MySwitch from "components/MySwitch";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { catchErrorMessage } from "utils/helper";
import { toast } from "react-toastify";
import useAuthStore from "store/useAuthStore";
import { useUserData } from "hooks/useUserData";
import { getContractInstance, web3 } from "utils/web3";
import CatergoryAPI from "api/CatergoryAPI";
import BrandAPI from "api/BrandAPI";
import { pinataImageService, pinataJsonService } from "api/PinataAPI";
import UserAPI from "api/UserAPI";
import MarketPlaceAPI from "api/MarketPlaceAPI";
import { IPFS_LINK } from "config/ipfs";
import Select from "shared/Select/Select";
import VideoForNft from "components/VideoForNft";


export interface PageUploadItemProps {
  className?: string;
}

const plans = [
  {
    name: "Crypto Legend - Professor",
    featuredImage: nftsImgs[0],
  },
  {
    name: "Crypto Legend - Professor",
    featuredImage: nftsImgs[1],
  },
  {
    name: "Crypto Legend - Professor",
    featuredImage: nftsImgs[2],
  },
  {
    name: "Crypto Legend - Professor",
    featuredImage: nftsImgs[3],
  },
  {
    name: "Crypto Legend - Professor",
    featuredImage: nftsImgs[4],
  },
  {
    name: "Crypto Legend - Professor",
    featuredImage: nftsImgs[5],
  },
];

export const CreateNftSchema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  category: Yup.string(),
  collection: Yup.string(),
  price: Yup.string(),
  startingDate: Yup.string(),
  edition: Yup.string(),
  currency: Yup.string()

});

const PageUploadItem: FC<PageUploadItemProps> = ({ className = "" }) => {
  const nftContractInstance = getContractInstance(false)
  const [categoryList, setCategoryList] = useState([]);
  const [collectionList, setCollectionList] = useState([])
  const [nftImage, setNFTImage] = useState({})
  const [values, setValue] = useState({ image: null, format: null, s3Url: null })
  const { user } = useUserData();
  const [nftObj, setNftObj] = useState<any>({})
  const [imageUrl, setImageURL] = useState('')
  const { walletAddress } = useAuthStore();
  const [brand, setBrand] = useState<any>([])
  const [isLoader, setisLoader] = useState(true)
  const [attributes, setAttributes] = useState<any>([])

  const [collSelected, setCollSelected] = useState('');
  const [catSelected, setCatSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(CreateNftSchema),
    defaultValues: {
      nftFile: '',
      title: '',
      description: '',
      category: '',
      collection: '',
      price: '',
      startingDate: '',
      brandId: '',
      edition: '',
      currency: 'MATIC'
    },
  });

  const watchFields = watch(['title', 'description', 'edition', 'currency', 'price']); // you can supply default value as second argument
  let fileInput: any = useRef()

  const currencyList = ['MATIC', 'USDC', 'TT', 'DERC20']


  useEffect(() => {
    (async () => {
      let result = await CatergoryAPI.getCategory();
      let collectionList = await CatergoryAPI.getCollection(user.user._id);
      let brands = await BrandAPI.getByUserId(user.user._id);
      if (brands?.data) setBrand(brands?.data)
      if (result?.data?.data) setCategoryList(result?.data?.data);
      if (collectionList?.data?.data) setCollectionList(collectionList?.data?.data)
      setisLoader(false)
    })()
  }, [])

  const fileChange = async (e: any) => {
    let file = e.target.files[0];
    const url = URL.createObjectURL(file)
    setImageURL(url)
    if (file) {
      // setisLoader(true)
      const fb = new FormData();
      fb.append('file', file)
      const pinataResult: any = await pinataImageService(fb)
      fb.append('fileName', pinataResult.data.IpfsHash)
      await UserAPI.upload(fb);
      let userObj = { image: pinataResult.data.IpfsHash, format: file.type, s3Url: `${pinataResult.data.IpfsHash}.${file.name.split('.').pop()}` };
      let temp: any = { ...values, ...userObj }
      setValue(temp)
      // setisLoader(false)
    }
  }



  const createNFT = async (data: any) => {
    console.log('here')
    setisLoader(true)
    let pinataContent = {
      image: `${IPFS_LINK}${values?.image}`,
      name: data.title,
      description: data.description,
      attributes
    }

    const ipfsExternalData = await pinataJsonService(pinataContent)
    let ipfsH = { original: values.image, compressed: values.image, format: values.format, s3Url: values.s3Url }
    let params: any = {
      title: data.title,
      image: ipfsH,
      externalNftHash: `${IPFS_LINK}${ipfsExternalData.data.IpfsHash}`,

      // externalNftHash: `${IPFS_LINK}${values.image}`,
      description: data.description,
      category: [catSelected],
      price: data.price,
      saleState: "BUY",
      currency: data.currency,
      auctionTime: Math.round(!data.startingDate ? new Date().valueOf() : new Date(data.startingDate).valueOf() / 1000),
      edition: data.edition,
      unlockContent: false,
      brandId: data?.brandId || null,
      attributes
    }
    if(collSelected){
      params.collectionId = collSelected;
    }
    setNftObj(params)
    await MarketPlaceAPI.mintNft(params)
    await triggerNFTMint(params)
    setisLoader(false)
  }

  const triggerNFTMint = async (params: any) => {
    try {
      console.log(params)
      let auctionDate = params.auctionTime
      let currency = '0x0000000000000000000000000000000000000000';
      let mintValue = 0;
      if (params.currency == 'TT') {
        currency = '0x879bad9DcD7e7f79B598a632103984FC090DA00D'
        //let result = await getContractDecimals('TT').methods.decimals().call();
        mintValue = web3.utils.toBN((Math.pow(10, +18) * (+params.price)))
      }
      if (params.currency == 'USDC') {
        currency = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
        // let result = await getContractDecimals('USDC').methods.decimals().call();
        mintValue = Math.pow(10, +6) * (+params.price)
      }
      if (params.currency == 'DERC20') {
        currency = '0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1'
        // let result = await getContractDecimals('USDC').methods.decimals().call();
        mintValue = web3.utils.toBN((Math.pow(10, +18) * (+params.price)))
      }

      await nftContractInstance.methods
        .mintToken(
          params.edition,
          params.externalNftHash,
          walletAddress,
          '0x0000000000000000000000000000000000000000',
          '100',
          '0',
          params.saleState === 'AUCTION' ? '1' : '0',
          params.saleState === 'AUCTION' ? [Math.round(auctionDate), params.auctionTime ? params.auctionTime : Number(0)] : [Math.round(auctionDate), Number(0)],
          params.currency == 'MATIC' ? web3.utils.toWei(params.price.toString(), 'ether') : mintValue,
          '0',
          currency
        )
        .send({ from: walletAddress })
        .on('transactionHash', (hash: any) => {
          // console.log('transaction hash : ', hash);
        })
        .on('receipt', (receipt: any) => {
          // console.log('on receipt ', receipt);
          setTimeout(() => {
            // refresh the state
            toast.success('NFT Minted Successfully.')
          }, 5000);
        })
        .on('error', (error: any) => {
          toast.error(error.message ? error.message : 'Something Went Wrong. Please try after sometime.')
        });
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddAttribute = () => {
    const values = [...attributes, { trait_type: '', value: '' }]
    setAttributes(values)
  }

  const handleRemoveAttribute = () => {
    const values = [...attributes];
    values.pop();
    setAttributes(values)
  }
  const handleAttribute = (value: any, key: any, index: number) => {
    const values = [...attributes];
    values[index] = !values[index] ? {} : values[index]
    values[index][key] = value;
    setAttributes(values);
  }


  return (
    <div
      className={`nc-PageUploadItem ${className}`}
      data-nc-id="PageUploadItem"
    >
      <Helmet>
        <title>Create Item || NFT React Template</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Create New Item
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name, create your profile URL and
              manage other personal settings.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(createNFT)}>

              <div>
                <h3 className="text-lg sm:text-2xl font-semibold">
                  Image, Video, Audio, or 3D Model
                </h3>
                <span className="text-neutral-500 dark:text-neutral-400 text-sm">
                  File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV,
                  OGG, GLB, GLTF. Max size: 100 MB
                </span>
                <div className="mt-5 ">
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-xl">
                    <div className="space-y-1 text-center">
                      {!imageUrl && !values.format ? <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg> : values.format === "video/mp4" ? <VideoForNft
                  nftId={values?.image || ''}
                  src={`https://nft-marketplace-faw.s3.ap-south-1.amazonaws.com/folder/${values?.s3Url}` || ''}
                  className="aspect-w-11 aspect-h-11 rounded-3xl overflow-hidden"
                /> :
                        <img src={imageUrl} />}
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id="file-upload"
                            name="nftFile"
                            type="file"
                            className="sr-only"
                            onChange={(e) => {
                              fileChange(e)
                            }}
                          />


                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">
                        PNG, MP4, JPG, GIF up to 10MB
                      </p>


                    </div>
                  </div>
                </div>
              </div>

              {/* ---- */}
              <FormItem label="Item Name">
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input type="text" className="validate mt-1" placeholder="e.g. Winnng moment from WC 1983"  {...field} />
                  )}
                  name="title"
                  defaultValue=""
                />
                <p className='input-error'>{errors?.title?.message}</p>
              </FormItem>



              {/* ---- */}
              <FormItem
                label="Description"

              >

                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Textarea className="validate mt-1.5" rows={6} style={{ height: "200px" }} placeholder='e.g. Captured from pavilion gallery when India won the world cup.'
                      required {...field}
                    />

                  )}
                  name="description"
                  defaultValue=""
                />

                <p className='input-error'>{errors?.description?.message}</p>
              </FormItem>
              <FormItem label="Select Brand">
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Select
                      {...field}
                      required
                    // onChange={({target:{value}}) => {
                    //    field.onChange(value)
                    // }}
                    >
                      <option>Select Brand</option>
                      {brand && brand.length === 0 && <option>no brand</option>}
                      {brand && brand.map((category: any, key: any) => {
                        return <option key={key} value={category.id}> {category.brandName} </option>
                      })}
                    </Select>

                  )}
                  name="brandId"
                  defaultValue=""
                />
              </FormItem>


              <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

              <div>
                <Label>Choose collection</Label>
                <div className="text-neutral-500 dark:text-neutral-400 text-sm">
                  Choose an collection
                </div>

                <RadioGroup value={collSelected} onChange={setCollSelected} name="collectionId">
                  <RadioGroup.Label className="sr-only">
                    collection
                  </RadioGroup.Label>
                  <div className="flex overflow-auto py-2 space-x-4 customScrollBar">
                    {collectionList.map((coll: any, index) => (
                      <RadioGroup.Option
                        key={index}
                        value={coll._id}
                        className={({ active, checked }) =>
                          `${active
                            ? "ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60"
                            : ""
                          }
                  ${checked
                            ? "bg-teal-600 text-white"
                            : "hover:bg-neutral-100 dark:hover:bg-neutral-800"
                          }
                    relative flex-shrink-0 w-44 rounded-xl border border-neutral-200 dark:border-neutral-700 px-6 py-5 cursor-pointer flex focus:outline-none `
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="flex items-center justify-between w-full">
                              <div className="flex items-center">
                                <div className="text-sm">
                                  <div className="flex items-center justify-between">
                                    <RadioGroup.Description
                                      as="div"
                                      className={"rounded-full w-16"}
                                    >
                                      <NcImage
                                        containerClassName="aspect-w-1 aspect-h-1 rounded-full overflow-hidden"
                                        src={coll.logo}
                                      />
                                    </RadioGroup.Description>
                                    {checked && (
                                      <div className="flex-shrink-0 text-white">
                                        <CheckIcon className="w-6 h-6" />
                                      </div>
                                    )}
                                  </div>
                                  <RadioGroup.Label
                                    as="p"
                                    className={`font-semibold mt-3  ${checked ? "text-white" : ""
                                      }`}
                                  >
                                    {coll.name}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>


              <div>
                <Label>Choose category</Label>
                <div className="text-neutral-500 dark:text-neutral-400 text-sm">
                  Choose an category
                </div>
                <RadioGroup value={catSelected} onChange={setCatSelected}>
                  <RadioGroup.Label className="sr-only">
                    category
                  </RadioGroup.Label>
                  <div className="flex overflow-auto py-2 space-x-4 customScrollBar">
                    {categoryList.map((coll: any, index) => (
                      <RadioGroup.Option
                        key={index}
                        value={coll._id}
                        className={({ active, checked }) =>
                          `${active
                            ? "ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60"
                            : ""
                          }
                  ${checked
                            ? "bg-teal-600 text-white"
                            : "hover:bg-neutral-100 dark:hover:bg-neutral-800"
                          }
                    relative flex-shrink-0 w-44 rounded-xl border border-neutral-200 dark:border-neutral-700 px-6 py-5 cursor-pointer flex focus:outline-none `
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="flex items-center justify-between w-full">
                              <div className="flex items-center">
                                <div className="text-sm">
                                  <div className="flex items-center justify-between">
                                    <RadioGroup.Description
                                      as="div"
                                      className={"rounded-full w-16"}
                                    >
                                      <NcImage
                                        containerClassName="aspect-w-1 aspect-h-1 rounded-full overflow-hidden"
                                        src={coll.image}
                                      />
                                    </RadioGroup.Description>
                                    {checked && (
                                      <div className="flex-shrink-0 text-white">
                                        <CheckIcon className="w-6 h-6" />
                                      </div>
                                    )}
                                  </div>
                                  <RadioGroup.Label
                                    as="p"
                                    className={`font-semibold mt-3  ${checked ? "text-white" : ""
                                      }`}
                                  >
                                    {coll.categoryName.en}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
              {/* <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-2.5">
                                        <div className="yoft-btn-section">
                                            <span style={{ fontSize: "20px", paddingRight: "10px" }}>Sale Type:</span>

                                            <button type='submit' className="yoft-btn">
                                                Buy Now
                                            </button>
                                        </div>
                                    </div> */}
                                    <FormItem
                  label="starting Date"

                >
                                        <Controller
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <Input type='date' className="validate" placeholder='starting Date'
                                                    required {...field}
                                                />

                                            )}
                                            name="startingDate"
                                        />

                                    </FormItem>
                                    <FormItem label="Select Currency" >
                                    <Controller
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <Select
                                                    {...field}
                                                    required
                                                // onChange={({target:{value}}) => {
                                                //    field.onChange(value)
                                                // }}
                                                >
                                                    <option>Select Currency</option>
                                                    {currencyList && currencyList.length === 0 && <option>No Currency</option>}
                                                    {currencyList && currencyList.map((curr: any, key) => {
                                                        return <option key={key} value={curr}> {curr} </option>
                                                    })}
                                                </Select>

                                            )}
                                            name="currency"
                                            defaultValue=""
                                        />
                                    </FormItem>

              {/* ---- */}
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-2.5">
                {/* ---- */}
                <FormItem
                  label="Price"

                >

                  <Controller
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input className="validate"
                        required {...field}
                      />

                    )}
                    name="price"
                    defaultValue=""
                  />

                  <p className='input-error'>{errors?.description?.message}</p>
                </FormItem>
                <FormItem
                  label="Edition"

                >

                  <Controller
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input className="validate"
                        required {...field}
                      />

                    )}
                    name="edition"
                    defaultValue=""
                  />

                  <p className='input-error'>{errors?.description?.message}</p>
                </FormItem>


              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-2.5">
                <FormItem
                  label="Attributes"

                ></FormItem>
                <div className="flex justify-between">
                  <ButtonPrimary type="button" className={'active'} onClick={handleAddAttribute}>Add</ButtonPrimary>

                  {
                    attributes.length !== 0 && <ButtonPrimary type="button" className={'active'} onClick={handleRemoveAttribute}>Remove</ButtonPrimary>
                  }
                </div>
              </div>

              {/* ---- */}
              {
                attributes.map((ele: any, index: number) => {
                  return (
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-2.5">
                      <FormItem
                        label="Trait Type"

                      >

                        <Input type='text' required value={ele.trait_type} placeholder='Trait Type' className="validate" onChange={(e) => handleAttribute(e.target.value, 'trait_type', index)} />

                        <p className='input-error'>{errors?.description?.message}</p>
                      </FormItem>
                      <FormItem
                        label="Trait Value"

                      >

                        <Input type='text' required value={ele.value} placeholder='Trait Value' className="validate" onChange={(e) => handleAttribute(e.target.value, 'value', index)} />
                      </FormItem>
                    </div>

                  )
                })
              }





              {/* ---- */}
              <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                <ButtonPrimary className="flex-1" >Upload item</ButtonPrimary>
                {/* <ButtonSecondary className="flex-1">Preview item</ButtonSecondary> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PageUploadItem;

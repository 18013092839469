import axios from 'axios';
import { API_BASE_URL } from '../constants/endpoints';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});


apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    return Promise.reject(error);
  },
);


export default apiClient;

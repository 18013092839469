import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import OneStopImg from "../../assets/images/one-stop-solution.gif"
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionPlatformIntegrationProps {
    className?: string;
}

const SectionPlatformIntegration: FC<SectionPlatformIntegrationProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-SectionPlatformIntegration relative flex flex-col-reverse lg:flex-row lg:items-center ${className}`}
            data-nc-id="SectionPlatformIntegration"
        >
            <div className="">
                <NcImage src={OneStopImg} />
            </div>
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:w-1/2 px-8">
                <p className="font-medium my-6 text-neutral-700 dark:text-neutral-300">
                    Integration
                </p>
                <h2 className="font-semibold text-4xl">Seamless IT Integration for Hassle-Free Subscription Management.</h2>
                <span className="block mt-5 text-neutral-500 dark:text-neutral-100">
                Yoft integrates seamlessly with all major IT solutions, allowing for easy and hassle-free subscription management. Our system is designed to effortlessly integrate with users' existing platforms, enabling smooth transitions between various ecosystems.
                </span>
            </div>

        </div>
    );
};

export default SectionPlatformIntegration;

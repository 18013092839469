import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import SubscriptionImg from "../../assets/images/subscription-image.png"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";

export interface SectionSubscriptionProps {
    className?: string;
}

const SectionSubscription: FC<SectionSubscriptionProps> = ({ className = "" }) => {
    const navigate = useNavigate()
    return (
        <div
            className={`nc-SectionSubscription relative flex flex-col lg:flex-row lg:items-center ${className}`}
            data-nc-id="SectionSubscription"
        >
            <div className="">
                <NcImage src={SubscriptionImg} />
            </div>
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:w-1/2">
                <h2 className="font-semibold text-4xl">The World's First Subscription Exchange Platform</h2>
                <span className="block mt-5 text-neutral-500 dark:text-neutral-100">
                Yoft is revolutionizing the subscription industry with the world's first subscription exchange platform. We empower customers to buy, trade, rent and swap their subscriptions with ease, giving them the flexibility they crave. At the same time, we help brands increase their revenue by unlocking the value of long-term subscriptions. With Yoft, you can own your subscriptions and enjoy complete control over them, without the hassle of cancellation or termination fees. Join us and experience a whole new world of subscription flexibility.
                </span>

                <ButtonPrimary
                    type="submit"
                    className="mt-8"
                    onClick={()=>navigate('/about')}
                >
                    Know Our Story
                </ButtonPrimary>
            </div>

        </div>
    );
};

export default SectionSubscription;

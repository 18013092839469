import React, { useEffect, useState } from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import { Helmet } from "react-helmet";
import SectionLargeSlider from "./SectionLargeSlider";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionGridFeatureNFT2 from "./SectionGridFeatureNFT2";
import SectionMagazine8 from "components/SectionMagazine8";
import SectionSliderCardNftVideo from "components/SectionSliderCardNftVideo";
import SectionSliderCollections2 from "components/SectionSliderCollections2";
import SectionSliderBrands from "components/SectionSliderBrands/SectionSliderBrands";
import BrandAPI from "api/BrandAPI";
import SectionSubscription from "components/subscription/subscription";
import SectionMobileApp from "components/mobileApp/mobileApp";
import SectionPlatformIntegration from "components/platformIntegration/platformIntegration";
import SectionOfferBrand from "components/offerBrand/offerBrand";
import SectionSliderMedia from "components/SectionSliderBrands/SectionSliderMedia";
import SectionBrandBlog from "components/brand-blog/brand-blog"
import SectionHomeBlog from "components/BlogHome/BlogSection"
import BrandDetailPage from "../Brands/BrandDetail"

import CookieConsent from "react-cookie-consent";
import PopupLeadGen from "components/popups/leadGen"
import LeadGenWidget from "components/Widget/LeadGenWidget"
interface PageHomeProps {
  subDomain: string;
}
function PageHome({ subDomain }: PageHomeProps) {
  const [brandList, setBrandList] = useState<any>([])
  const [isLoader, setIsLoader] = useState<any>(true)
  const [brandDetails, setBrandDetails] = useState(null);
  const [isSubdomain, setIsSubdomain] = useState(false);


  useEffect(() => {
    (async () => {
      console.log("subDomain",subDomain)
      // if (subDomain == "localhost") {
      if (subDomain == "www") {
      setIsLoader(true)
      const result = await BrandAPI.getBrand();
      setBrandList(result?.data);
      setIsLoader(false)
      }else{
        //it is a subdomain
        setIsSubdomain(true)
      }
    })()
  }, [])


  const YoftHomePageContent = () => (
    <div className="nc-PageHome relative overflow-hidden homeLayout">
      <Helmet>
        <title>Unleash the Potential of Your Subscription Business with a Tradable Platform | YOFT</title>
        <meta name="description" content="Unlock the potential of your subscription business with YOFT. Our platform allows customers to resell subscriptions, increasing retention and creating new sales channels." />
        <meta property="og:url"                content="https://yoft.xyz" />
        <meta property="og:type"               content="website" />
        <meta property="og:title"              content="Unleash the Potential of Your Subscription Business with a Tradable Platform | YOFT" />
        <meta property="og:description"        content="Unlock the potential of your subscription business with YOFT. Our platform allows customers to resell subscriptions, increasing retention and creating new sales channels." />
        <meta property="og:image"              content="https://www.yoft.xyz/static/media/mobile.5536f66418b5f862ed87.png" />

      </Helmet>
      <BgGlassmorphism />
      <div className="container relative mb-20 sm:mb-24  lg:mb-32">
        <SectionHero2 />
        <div className="mt-24 lg:mt-32">
          <SectionSliderBrands heading="Top Subscription Partners" subHeading="Explore the tradable subscriptions from top brands." data={brandList} isLoader={isLoader} />
        </div>
        <div className="mt-24 lg:mt-32">
          <SectionSliderMedia heading="Media Coverage" subHeading="" data={[]} />
        </div>
      </div>
      <div className="container relative space-y-8 ">
        <SectionSubscription />
        <SectionMobileApp />
        <SectionBrandBlog />
        <SectionPlatformIntegration />
        <SectionOfferBrand />
        <SectionHomeBlog />
        <SectionSubscribe2 />
      </div>
    </div>
  );


  return (
    <div className="nc-PageHome relative overflow-hidden homeLayout">
      {isSubdomain ? (
        // Render PageBrandDetail component for subdomains
        <BrandDetailPage  subdomain={subDomain} />
        
      ) : (
        // Render YoftHomePageContent component for root domain
        <YoftHomePageContent />
      )}
    </div>
  );
};
export default PageHome;

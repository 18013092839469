import React, { useMemo, useState } from 'react';
import Bicono from 'assets/images/bicono.png';
import Dutch from 'assets/images/dutch.png';
import Halwing from 'assets/images/halwing.png';
import Hatch from 'assets/images/hatch.png';
import Hello from 'assets/images/hello.png';
import Hotbit from 'assets/images/hotbit.png';
import Ibc from 'assets/images/ibc.png';
import Magnus from 'assets/images/magnus.png';
import Moon from 'assets/images/moon.png';
import Oasis from 'assets/images/oasis.png';
import PolygonStudios from 'assets/images/polygon-studios.png';
import R930 from 'assets/images/r-930.png';
import Sevenoclock from 'assets/images/sevenoclock.png';
import Shimacapital from 'assets/images/shimacapital.png';
import Torus from 'assets/images/torus.png';
import Unilayer from 'assets/images/unilayer.png';
import Wert from 'assets/images/wert.png';
import Zbs from 'assets/images/zbs.png';



const OurInvestors = () => {


   return (
    <div className="brand featured investor-section center hp-section text-center">
            
         </div>
         );
};

export default OurInvestors;

import React, { FC, useEffect, useState } from "react";




const TorusWalletAuthorize: FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [usdtBalance, setUSDTBalance] = useState<any>('00.00');

  let torus:any;

  useEffect(() => {

    const authorize = async () => {
      setLoading(true)
      // torus = new TorusSdk({
      //   baseUrl: `${window.location.origin}`,
      //   redirectPathName: "auth",
      //   uxMode: "redirect",
      //   enableLogging: false,
      //   network: 'testnet' // optional
      // });
      await torus.init({
        skipSw: true
      });
      const d = await torus.getRedirectResult()
      console.log(d)
      // const userInfo = await torus.getUserInfo();
    }
    if(!loading)
    authorize()
  }, [])


  return (
    <div className="min-h-[400px]">
      <p className="p-12">Authorize...</p>
    </div>
  );
};

export default TorusWalletAuthorize;

import { API_URLS } from '../constants/endpoints';
import APIAbstract from './APIAbstract';

export interface Address {
  address: string;
}

class Web3API extends APIAbstract {

   public generateNonce(address:Address) {
    return this.get(`${API_URLS.GENERATE_NONCE}/${address}`);
  }

  public authenticateWallet(nonce:string, signature:string) {
    return this.post(API_URLS.WALLET_AUTHENTICATE,{nonce,signature});
  }


}

export default new Web3API();
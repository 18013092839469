import React, { useMemo, useState } from 'react';
import Varun from 'assets/images/Varun.png'
import Amit from 'assets/images/Amit.png'
import Andrew from 'assets/images/andrew.png'
import Yuvaraj from 'assets/images/Yuvaraj.png'
import Rohan from 'assets/images/Rohan.png'
import Siddharth from 'assets/images/Siddharth.png'
import Saharsh from 'assets/images/saharsh.png'
import Bruno from 'assets/images/Bruno.png'
import Disha from 'assets/images/Disha.png'
import LinkedInIcon from 'assets/images/linkedin-icon.png';
import NcImage from 'shared/NcImage/NcImage';



const OurTeam = () => {

   return (

      <div className="our-team-section text-center">
         
         
      </div>

   );
};

export default OurTeam;

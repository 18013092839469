import { API_URLS } from '../constants/endpoints';
import APIAbstract from './APIAbstract';

class SubscriptionAPI extends APIAbstract {

  public subscribe(payload:any) {
    return this.post(API_URLS.SUBSCRIPTION, payload);
  }

}

export default new SubscriptionAPI();
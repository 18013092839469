import React, { useMemo, useState } from 'react';
import SendResume from 'assets/images/send-resume-image.png';

const JoinTeam = () => {


   return (
      <div className="join-team-bg">
         <div className="join-team-section">
            <div className="jt-left">
               <img src={SendResume} />
            </div>
            <div className="jt-right">
               <div className="send-resume-desc">
                  <a href="mailto:jobs@yoft.xyz">Send in your resume to  contact@yoft.xyz</a>
               </div>
            </div>
         </div>
      </div>
   );
};

export default JoinTeam;

import rightImg from "assets/images/about-banner-image.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import OurTeam from "./OurTeam";
import OurAdvisory from "./OurAdvisory";
import SectionOpenPosition from "./OpenPosition";
import OurInvestors from "./OurInvestors";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Meet the Team - YOFT, the Innovative Platform for Buying and Selling Subscriptions</title>
        <meta name="description" content="Meet the team behind Yoft.xyz, the innovative platform for buying and selling subscriptions with a unique tradable subscription model. Our dedicated and passionate team is committed to revolutionizing the way subscriptions are bought and sold. Learn more about us and our mission." />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-16 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 Our Story"
          btnText=""
          subHeading={<span>Today, subscriptions are the most common form of payment. People are accessing all kinds of goods and services as subscriptions. And why not, they’re convenient.
            <br />
            <br />
            However, subscribers end up spending a lot, getting stuck in free trials, complicated unsubscription processes, hidden charges, or simply, not using the subscription. They also miss out on the discounts on annual subscriptions because they do not want to have a long-term commitment.
            <br />
            <br />
            Imagine users being able to trade subscriptions anytime they want.
            <br />
            <br />
            Blockchain has the potential to accomplish that. Turning subscriptions into bearer assets is better for everyone. In fact, this is the better form of paywall.
            <br />
            <br />
            Yoft acts as a mediator between brands and users to inspire long-term commitments as an on-chain asset, enabling users with the flexibility to transfer ownership of subscriptions to new users."
          </span>}
        />

        <div
          className={`nc-SectionHero relative ${className}`}
          data-nc-id="SectionHero"
        >
          <div className="flex  w-full space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
            <div className="w-full xl:w-lg space-y-5 lg:space-y-7">
              <h2 className="text-3xl !leading-tight text-center font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                Our Philosophy
              </h2>
              <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                We believe that people should be able to get the most out of their subscription - as long as they want to. Subscriptions as tradable asset give decentralized ownership of exclusive rights to the holder and enable them to transfer this ownership whenever they please.
                <br />
                <br />
                We aim to liberate people from the binding commitments of subscriptions by making subscriptions a tradeable token - thus bringing flexibility into the subscription model. This enables brands and users to engage in long-term commitments!
              </span>
            </div>
          </div>
        </div>



        <div
        id="our-team"
          className={`nc-SectionHero relative ${className}`}
          data-nc-id="SectionHero"
        >
          <div className="flex  w-full space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
            <div className="w-full xl:w-lg space-y-5 lg:space-y-7">
              <h2 className="text-3xl mb-16 !leading-tight text-center font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                Our Team
              </h2>
              <OurTeam />

            </div>
          </div>
        </div>

        <div
          className={`nc-SectionHero relative ${className}`}
          data-nc-id="SectionHero"
        >
          <div className="flex  w-full space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
            <div className="w-full xl:w-lg space-y-5 lg:space-y-7">
              <h2 className="text-3xl mb-16 !leading-tight text-center font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                Advisory Board
              </h2>
              <OurAdvisory />

            </div>
          </div>
        </div>
        <OurInvestors />

        <SectionOpenPosition />

        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;

import React, { FC, useEffect, useRef, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import VerifyIcon from "components/VerifyIcon";
import { nftsLargeImgs, personNames } from "contains/fakeData";
import TimeCountDown from "./TimeCountDown";
import TabDetail from "./TabDetail";
import collectionPng from "images/nfts/collection.png";
import ItemTypeVideoIcon from "components/ItemTypeVideoIcon";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { Link, useParams } from "react-router-dom";
import { boolean } from "yup";
import MarketPlaceAPI from "api/MarketPlaceAPI";
import ItemTypeImageIcon from "components/ItemTypeImageIcon";
import VideoForNft from "components/VideoForNft";
import { Helmet } from "react-helmet";
import { signSmartContract } from "api/wert.service";
import CustomIframe from "modals/iframe";
import { useAuth } from "hooks/useAuth";
import { toast } from "react-toastify";
import { getContractInstance, get721ContractInstance, web3 } from "utils/web3";
import { contractAddresses } from "config/web3.config";
import { getTokenContractInstance } from "utils/functions";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcModal from "shared/NcModal/NcModal";
import useAuthStore from "store/useAuthStore";
import Loader from "components/Loader";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { BiTimeFive } from "react-icons/bi";
import moment from "moment";
import Explore from "../../assets/images/explore.svg";
import { FiClock } from "react-icons/fi";
import StripePaymentModel from "../stripe/stripePaymentPopup";
import { nftsImgs } from "contains/fakeData";

export interface NftDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const NftDetailPage: FC<NftDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const [details, setDetails] = useState<any>({});
  const [historyList, setHistoryList] = useState<any>([]);
  const [isLoader, setisLoader] = useState(true);
  const shareRef: any = useRef();
  const userData = useAuth();
  const [wertSrc, setWertSrc] = useState(null);
  const [stripeSrc, setStripeSrc] = useState(null);
  const [stripeModalOpen, setStripeModalOpen] = useState<boolean>(false);

  const [modalOpen, setModelOpen] = useState<boolean>(false);
  const [loaderText, setTxnStatus] = useState<string>("");
  const [isTextloader, setIsTextloader] = useState<boolean>(false);

  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [ext, setExt] = useState<any>(false);
  const [walletDetails, setWalletDetails] = useState<any>(false);
  const [buyWallet, setBuyWallet] = useState<boolean>(false);
  const [isWalletLess, setIsWalletLess] = useState(false);
  const [openForth, setOpenForth] = useState(false);
  let escrowContractInstance = getContractInstance(true);
  let erc721_ContractInstance = get721ContractInstance(true);

  const { walletAddress } = useAuthStore();

  let { id } = useParams();
  useEffect(() => {
    (async () => {
      const result = await MarketPlaceAPI.getById(id);
      const history = await MarketPlaceAPI.gethistoryId(id);

      if (result?.data?.data) setDetails(result?.data?.data);
      if (history?.data?.data) setHistoryList(history?.data?.data);
      setExt(
        result?.data?.data.image.format.includes("video") ? "video" : "image"
      );
      setisLoader(false);
    })();
  }, [id]);

  const getNftDetails = (data: any, sale_Type: any) => {
    if (sale_Type === 1) {
      const { nonce, price } = data;
      const editionNo = data.availableEdition[0];
      return { nonce: +nonce, editionNo, price };
    }
    if (sale_Type === 2) {
      const { nonce, edition: editionNo } = data;
      const price = data.saleType.price;
      return { nonce: +nonce, editionNo, price };
    }
  };

  const handleWert = async (
    nonce: any,
    edition: any,
    price: any,
    currency: any
  ) => {
    const url = await signSmartContract(
      nonce,
      edition,
      price,
      details.id,
      currency,
      details.ownerId.profile,
      details.ownerId.name,
      details.image?.s3Url,
      details.title,
      details.ownerId.name,
      userData
    );
    // await MarketPlaceAPI.updateEditionOnBuy(details.id)
    setWertSrc(url);
    setModelOpen(true);
  };

  const handleStripe = async (price: any, currency: any) => {
    // await MarketPlaceAPI.updateEditionOnBuy(details.id)
    //setStripeSrc(price);
    window.location.href = "/payment";
    // setStripeModalOpen(true)

    //setModelOpen(true);
  };

  const onSuccess = () => {
    // props.getNFT(id)
    // props.getLikesCount(id)
    // props.getIsLiked(id)
    // props.getHistory(id)
  };

  const buyNFT = async (details: any, sale_Type: any, walletType: any = "") => {
    const { nonce, editionNo, price }: any = getNftDetails(details, sale_Type);

    /* let balance: any = Number(
      web3.utils.fromWei(await web3.eth.getBalance(walletAddress))
    );
    balance = balance.toLocaleString(undefined, 2); */
    let val = price.toString();
    if (!walletType) {
      /* if (+balance < +val) {
        setIsWalletLess(true);
        // setTimer(false)
        // handleWert(nonce, editionNo, price)
      } */
      window.location.href = `/payment?id=${details._id}`;

      //setBuyWallet(true);
      //return setWalletDetails({ ...details, sale_Type });
    }

    if (details.ownerId === userData.user.id) {
      toast.warning("Owner can not buy edition.");
    } else if (walletType === "card") {
      window.location.href = `/payment?id=${details._id}`;

      //window.location.href = "/payment";

      //setIsTextloader(true);
      //handleWert(nonce, editionNo, price, details.currency);
      //handleStripe(price, details.currency);

      //await MarketPlaceAPI.updateEditionOnBuy(details.id);
    } else {
      setIsTextloader(true);
      if (!escrowContractInstance) {
        escrowContractInstance = getContractInstance(true);
      }
      let decimals = 0;
      let result = 6;
      let allowance;
      let value = web3.utils.toWei(val);
      if (details.currency != "MATIC") {
        if (details.currency == "DERC20") {
          result = 18;
          decimals = web3.utils.toBN(Math.pow(10, +result) * +price);
          allowance = web3.utils.toBN(Math.pow(10, +result) * 10 * +price);
          value = 0;
        } else if (details.currency == "USDC") {
          result = 6;
          decimals = Math.pow(10, +result) * +price;
          allowance = Math.pow(10, +result) * 2 * +price;
          value = 0;
        }
        const { escrowContractAddres } = contractAddresses;

        let dataa = await getTokenContractInstance(details.currency)
          .methods.allowance(escrowContractAddres, walletAddress)
          .call();
        await getTokenContractInstance(details.currency)
          .methods.approve(escrowContractAddres, allowance)
          .send({ from: walletAddress, value: 0 })
          .on("transactionHash", (hash: any) => {
            // setIsTextloader(false)
            console.log(hash);
            return;
          })
          .on("receipt", async (receiptt: any) => {
            await escrowContractInstance.methods["buyNow"](
              nonce,
              editionNo,
              decimals
            )
              .send({ from: walletAddress, value: value })
              .on("transactionHash", async (hash: any) => {
                toast.info("Transaction Initiated.");
                setTxnStatus("progress"); // second step for transaction
                if (sale_Type === 1) {
                  await MarketPlaceAPI.updateEditionOnBuy(details.id);
                }
              })
              .on("receipt", (receipt: any) => {
                setTimeout(() => {
                  onSuccess(); // refresh the state
                  setTxnStatus("complete"); // third step for transaction
                  // setTimer(false)
                }, 7000);
              })
              .on("error", (error: any) => {
                console.log(error, "error");
                // setIsTextloader(false)
                setTxnStatus("error"); // four step for transaction
                toast.error(
                  error.message
                    ? error.message
                    : "Something Went Wrong. Please try after sometime."
                );
              });
          })
          .on("error", (error: any) => {
            console.log(error, "error");
            // setIsTextloader(false)
          });
      } else {
        console.log(":::::", decimals, val);

        const gasPrice = await web3.eth.getGasPrice();
        const sendGasPrice = Math.floor(1.6 * gasPrice);
        console.log("send gas::", sendGasPrice, nonce, editionNo, decimals);

        if (details.nftType === "erc_721") {
          await erc721_ContractInstance.methods["mintWithCreatorSig"](
            "https://ipfs.io/ipfs/Qmak43uLeeGDn2zvw8ETqMoHKTwFsdY9UVpGgdVxfvkfoG",
            1000,
            100000000,
            28,
            "0x21002750ba83342eb7978e490738d700f46fe0fc6ea53ba651c3b23db4caa5f6",
            "0x01fb4835e9fc5df3da37d49865d5c0168423ceda136740a1c65b8929f53f6c04"
          )
            .send({
              from: walletAddress,
              value: 100000000,
              gasPrice: sendGasPrice,
            })
            .on("transactionHash", async (hash: any) => {
              toast.info("Transaction Initiated.");
              setTxnStatus("progress"); // second step for transaction
              if (sale_Type === 1) {
                await MarketPlaceAPI.updateEditionOnBuy(details.id);
              }
            })
            .on("receipt", (receipt: any) => {
              setTimeout(() => {
                toast.success("Transaction successful");
                onSuccess(); // refresh the state
                setTxnStatus("complete"); // third step for transaction
                // setTimer(false)
                //  setIsTextloader(false)
              }, 7000);
            });
        } else {
          await escrowContractInstance.methods["buyNow"](
            nonce,
            editionNo,
            decimals
          )
            .send({ from: walletAddress, value: value, gasPrice: sendGasPrice })
            .on("transactionHash", async (hash: any) => {
              toast.info("Transaction Initiated.");
              setTxnStatus("progress"); // second step for transaction
              if (sale_Type === 1) {
                await MarketPlaceAPI.updateEditionOnBuy(details.id);
              }
            })
            .on("receipt", (receipt: any) => {
              setTimeout(() => {
                toast.success("Transaction successful");
                onSuccess(); // refresh the state
                setTxnStatus("complete"); // third step for transaction
                // setTimer(false)
                //  setIsTextloader(false)
              }, 7000);
            })
            .on("error", (error: any) => {
              console.log(error, "error");
              // setTimer(false)
              // setIsTextloader(false)
              setTxnStatus("error"); // four step for transaction
              toast.error(
                error.message
                  ? error.message
                  : "Something Went Wrong. Please try after sometime."
              );
            });
        }
      }
    }
  };

  const handleBuy = (data: any, sale_Type: any) => {
    setTxnStatus("");

    if (!userData.user) {
      setOpenForth(true);
    } else {
      buyNFT(data, sale_Type);
    }

    /* if (!walletAddress) {
      setOpenForth(true);
      setWalletDetails({ ...data, sale_Type });
    } else {
      buyNFT(data, sale_Type);
    } */
  };

  const handleClose = () => {
    setModelOpen(false);
  };

  const renderSigninWallet = () => {
    return (
      <form action="#">
        <span className="text-sm">Please Sign in with your account</span>
        <div className="mt-5 space-x-3 flex justify-end">
          <ButtonPrimary>
            <Link to={`/login?redirect=${window.location.pathname}`}>
              Sign in
            </Link>
          </ButtonPrimary>
        </div>
      </form>
    );
  };

  /* const renderSigninWallet = () => {
    return (
      <form action="#">
        <span className="text-sm">
          {userData && !userData.user
            ? "Please Sign in with account"
            : "Please connect with wallet."}
        </span>
        <div className="mt-5 space-x-3 flex justify-end">
          {userData && !userData.user ? (
            <ButtonPrimary>
              <Link to={`/login?redirect=${window.location.pathname}`}>
                Sign in
              </Link>
            </ButtonPrimary>
          ) : (
            <ButtonPrimary className="ml-2" disabled={isWalletLess}>
              <Link to={`/connect-wallet?redirect=${window.location.pathname}`}>
                Connect Wallet
              </Link>
            </ButtonPrimary>
          )}
        </div>
      </form>
    );
  }; */

  const renderPaymentModal = () => {
    return (
      <form action="#">
        {isWalletLess && (
          <span className="text-sm">
            Your Wallet Amount is less then nft amount
          </span>
        )}
        <div className="mt-5 space-x-3 flex justify-end">
          <ButtonPrimary
            type="button"
            onClick={() => {
              buyNFT(walletDetails, walletDetails.sale_Type, "card");
              setBuyWallet(false);
            }}
          >
            Stripe Payment
          </ButtonPrimary>
          {/* <ButtonPrimary
            type="button"
            className="ml-2"
            disabled={isWalletLess}
            onClick={() => {
              buyNFT(walletDetails, walletDetails.sale_Type, "wallet");
              setBuyWallet(false);
            }}
          >
            Buy With Wallet
          </ButtonPrimary> */}
        </div>
      </form>
    );
  };

  const renderLoaderModal = () => {
    return (
      // <form action="#">
      <div className="space-x-3 flex flex-col justify-center">
        <div className="flex justify-center">
          <p className="text-sm py-4">{`Your transaction ${
            loaderText ? loaderText : "Initiated"
          }...`}</p>
        </div>
        <div className="relative mb-16">
          {loaderText !== "complete" && <Loader />}
        </div>
      </div>
      // </form>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
        {isLoader ? (
          <Loader />
        ) : (
          <>
            <div className="pb-9 space-y-5">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                {details?.title}
              </h2>

              <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
                <div className="flex items-center">
                  <Avatar
                    imgUrl={details?.brandId?.brandLogoS3URL}
                    sizeClass="h-9 w-9"
                    radius="rounded-full"
                  />
                  <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                    <span className="text-sm">Offered by</span>
                    <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                      {/* <span>{details?.collectionId?.name}</span> */}
                      <span>{details?.brandId?.brandName}</span>
                    </span>
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-1 h-6 bg-neutral-300 dark:bg-neutral-700">
                    {" "}
                  </div>{" "}
                  {/* Separator */}
                  <div className="flex items-center text-neutral-500 dark:text-neutral-400">
                    <BiTimeFive className="h-4 w-4" />
                    <span className="font-medium px-2">Validity:</span> 1 year
                  </div>
                </div>
              </div>
            </div>

            <div className="pb-0 py-5 space-y-5">
              <div className="text-neutral-900 dark:text-neutral-200 font-normal leading-loose">
                <strong>Inclusions</strong>
                {details?.description &&
                  details.description
                    .split("<br/>")
                    .map((line: string, index: number) => (
                      <div key={index} className="mb-4">
                        <p className="flex items-start mb-1">
                          <span className="mr-2">
                            <HiOutlineCheckCircle className="inline-block text-green-500 text-xl" />
                          </span>
                          {line}
                        </p>
                      </div>
                    ))}
              </div>
            </div>

            <div className="pb-9 pt-14">
              <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
                <div className="flex-1 flex flex-col sm:flex-row items-baseline p-2 sm:p-2 border-2 border-green-500 rounded-xl relative">
                  <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
                    Price
                  </span>
                  <span className="text-xl sm:text-2xl font-semibold text-green-500">
                    {details?.price} {details?.currency}
                  </span>
                </div>

                {/*   <span className="text-sm text-neutral-500 dark:text-neutral-400 ml-5 mt-2 sm:mt-0 sm:ml-4">
    [{details?.edition - details?.nftSold} in stock]
  </span> */}
              </div>

              <div className="mt-8 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
                {details?.ownerId?.id !== userData?.user?.user?.id && (
                  <ButtonPrimary
                    onClick={() => handleBuy(details, 1)}
                    className="flex-1"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7 12H14"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="ml-2.5">
                      Buy for {details?.price} {details?.currency || "MATIC"}
                    </span>
                  </ButtonPrimary>
                )}
              </div>
            </div>
            <div className="pt-9">
              <TabDetail data={details} historyList={historyList} />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-NftDetailPage  ${className}`}
      data-nc-id="NftDetailPage"
    >
      <Helmet>
        <title>Nft Details</title>
      </Helmet>
      {/* MAIn */}
      <main className="container pt-8 flex ">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
          {/* CONTENT */}
          <div className="space-y-8 lg:space-y-10">
            {/* HEADING */}
            <div className="relative">
              {ext === "video" ? (
                <VideoForNft
                  nftId={details?.id}
                  src={details?.image?.s3Url}
                  className="aspect-w-11 aspect-h-11 rounded-3xl overflow-hidden"
                />
              ) : (
                <NcImage
                  src={details?.image?.s3Url}
                  containerClassName="aspect-w-11 aspect-h-11 rounded-3xl overflow-hidden"
                />
              )}

              {/* META TYPE */}
              {ext === "video" ? (
                <ItemTypeVideoIcon className="absolute left-3 top-3  w-8 h-8 md:w-10 md:h-10" />
              ) : (
                <ItemTypeImageIcon className="absolute top-3 left-3 !w-9 !h-9" />
              )}

              {/* META FAVORITES */}
              <LikeButton
                id={details?._id}
                liked={details?.isLiked}
                className="absolute right-3 top-3 "
              />
            </div>

            {/* <AccordionInfo data={details} /> */}
          </div>

          {/* SIDEBAR */}
          <div className="relative pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
            {renderSection1()}
          </div>
        </div>
      </main>

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container ">
          {/* SECTION 1 */}
          <div className="relative py-4 ">
            {/* <BackgroundSection /> */}
            {/* <SectionSliderCategories /> */}
          </div>

          {/* SECTION */}
          {/* <SectionBecomeAnAuthor className="pt-24 lg:pt-32" /> */}
        </div>
      )}

      {/* If Wart Payment */}
      {wertSrc && (
        <CustomIframe
          src={wertSrc}
          modalOpen={modalOpen}
          handleClose={handleClose}
        />
      )}

      {/* Render the StripePaymentPopup component */}
      {/* {stripeModalOpen && <StripePaymentModel />} */}

      {/* Select Payment Mode */}
      <NcModal
        renderTrigger={() => null}
        isOpenProp={buyWallet}
        renderContent={renderPaymentModal}
        contentExtraClass="max-w-md"
        onCloseModal={() => setBuyWallet(false)}
        modalTitle="Available Payment Options"
      />

      <NcModal
        renderTrigger={() => null}
        isOpenProp={openForth}
        renderContent={renderSigninWallet}
        contentExtraClass="max-w-md"
        onCloseModal={() => setOpenForth(false)}
        modalTitle="Sign In"
      />

      {/* Signin Or Connect Wallet */}
      {/*     <NcModal
        renderTrigger={() => null}
        isOpenProp={openForth}
        renderContent={renderSigninWallet}
        contentExtraClass="max-w-md"
        onCloseModal={() => setOpenForth(false)}
        modalTitle={userData && !userData.user ? "Sign In" : "Connect Wallet"}

        // modalTitle={userData && !userData.user ? "Sign In" : "Connect Wallet"}
      /> */}

      {/* Signin Or Connect Wallet */}
      <NcModal
        renderTrigger={() => null}
        isOpenProp={isTextloader}
        renderContent={renderLoaderModal}
        contentExtraClass="max-w-md"
        onCloseModal={() => setIsTextloader(false)}
        modalTitle="Transaction Status"
      />
    </div>
  );
};

export default NftDetailPage;

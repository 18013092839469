import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import HandsImg from "../../assets/images/hands.png"
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionOfferBrandProps {
    className?: string;
}

const SectionOfferBrand: FC<SectionOfferBrandProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-SectionOfferBrand relative flex flex-col lg:flex-row lg:items-center ${className}`}
            data-nc-id="SectionOfferBrand"
        >

            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:w-1/2">
                <h2 className="font-semibold text-4xl">Offer More Flexibility and Convenience to Your Subscribers</h2>
                <span className="block mt-5 text-neutral-500 dark:text-neutral-100">
                Join us as a brand and give your subscribers the flexibility they need without worrying about cancellations. Our platform allows for subscriptions to be traded like assets, providing greater value to your customers. By becoming part of our decentralized platform, you can be part of the future of web 3.0 and offer a more seamless subscription experience for your users.
                </span>
                <ButtonPrimary
                    type="submit"
                    className="mt-8"
                >
                    <a href="https://form.jotform.com/231481258833459" target={"_blank"}>
                        Become a brand partner
                    </a>
                </ButtonPrimary>
            </div>
            <div className="">
                <NcImage src={HandsImg} />
            </div>
        </div>
    );
};

export default SectionOfferBrand;

import Attractive from 'assets/images/attractive.png';
import Progreesive from 'assets/images/progreesive.png';
import Decentrlized from 'assets/images/decentrlized.png';
import Global from 'assets/images/global.png';
import Empowered from 'assets/images/empowered.png';
import Blockchain from 'assets/images/blockchain.png';
import Innovation from 'assets/images/innovation-img.png';
import Openness from 'assets/images/openness.png';
import Collabaration from 'assets/images/collabaration-img.png';
import ProductManager from 'assets/images/productManager.png';
import BlockchainManger from 'assets/images/blockchain-manger.png';
import BrandAq from 'assets/images/brand-aq.png';
import BrandMena from 'assets/images/brand-mena.png';
import Empathy from 'assets/images/empathy.png'


const Perks = () => {


   return (
      <div className="mt-20 ">

        {/*  <div className="employee-perk-section">
            <div className="container">
               <div className="yoft-heading center text-center">
                  Perks of being a Yofter
               </div>
               <div className="employee-perk">
                  <div className="employee-perk-content flex flex-row items-center">
                     <div className="employee-perk-img p-12">
                        <img src={Attractive} />
                     </div>
                     <div className="employee-perk-desc">
                        <div className="ep-title">Attractive Package</div>
                        <div className="ep-desc">Get an attractive pay package, ESOPs, and full ownership at YOFT!</div>
                     </div>
                  </div>
                  <div className="employee-perk-content flex flex-row items-center">
                     <div className="employee-perk-img p-12">
                        <img src={Progreesive} />
                     </div>
                     <div className="employee-perk-desc">
                        <div className="ep-title">Progressive Technology</div>
                        <div className="ep-desc">Work with the cutting edge technology and best minds in the space building something never done before in the world.</div>
                     </div>
                  </div>
                  <div className="employee-perk-content flex flex-row items-center">
                     <div className="employee-perk-img p-12">
                        <img src={Decentrlized} />
                     </div>
                     <div className="employee-perk-desc">
                        <div className="ep-title">Decentralized Ownership</div>
                        <div className="ep-desc">You will not be joining as an employee but as a team member and will be part of every decision we make! We will be paving the path to glory together.</div>
                     </div>
                  </div>
                  <div className="employee-perk-content flex flex-row items-center">
                     <div className="employee-perk-img p-12">
                        <img src={Global} />
                     </div>
                     <div className="employee-perk-desc">
                        <div className="ep-title">Global Exposure</div>
                        <div className="ep-desc">We are a distributed team based out of Amsterdam, Dubai and India. Get global exposure and work with an international team.</div>
                     </div>
                  </div>
                  <div className="employee-perk-content flex flex-row items-center">
                     <div className="employee-perk-img p-12">
                        <img src={Empowered} />
                     </div>
                     <div className="employee-perk-desc">
                        <div className="ep-title">Empowered Workstyle</div>
                        <div className="ep-desc">You get everything to help you excel at YOFT - including Macbooks/laptops. We also get you all the necessary subscriptions needed for optimum productivity.</div>
                     </div>
                  </div>
                  <div className="employee-perk-content flex flex-row items-center">
                     <div className="employee-perk-img p-12">
                        <img src={Blockchain} />
                     </div>
                     <div className="employee-perk-desc">
                        <div className="ep-title">Blockchain Ecosystem</div>
                        <div className="ep-desc">Work in a healthy community of like-minded blockchain enthusiasts - no corporate politics, only growth.</div>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}

         {/* <div className="our-culture center">
            <div className="container">
               <div className="our-culture-subsection text-center">

                  <div className="yoft-heading">
                     Our Culture
                  </div>
                  <div className="yoft-desc">
                     Here is what drives YOFT:
                  </div>
                  <div className="our-culture-content">
                     <div className='flex'>

                     <div className="our-culture-grid">
                        <div className="our-team-image">
                           <img src={Innovation} />
                        </div>
                        <div className="our-team-desc-section">
                           <div className="our-team-name">
                              Innovations
                           </div>
                        </div>
                     </div>
                     <div className="our-culture-grid">
                        <div className="our-team-image">
                           <img src={Empathy} />
                        </div>
                        <div className="our-team-desc-section">
                           <div className="our-team-name">
                              Empathy
                           </div>
                        </div>
                     </div>
                     <div className="our-culture-grid">
                        <div className="our-team-image">
                           <img src={Openness} />
                        </div>
                        <div className="our-team-desc-section">
                           <div className="our-team-name">
                              Openness
                           </div>
                        </div>
                     </div>
                     <div className="our-culture-grid">
                        <div className="our-team-image">
                           <img src={Collabaration} />
                        </div>
                        <div className="our-team-desc-section">
                           <div className="our-team-name">
                              Collaboration
                           </div>


                        </div>
                     </div>
                     </div>

                  </div>
               </div>
            </div>
         </div> */}


         <div id="our-position" className="find-where-main-section">
            <div className="container ">
               <div className="yoft-heading center">
                  We're Hiring!
               </div>
               <div className="yoft-desc center">
                  Check out open positions at Yoft right now.
               </div>

               <div className="find-where-section">
                  <div className="fw-grid">
                     <div className="fw-image">
                        <img src={ProductManager} />
                     </div>
                     <div className="yoft-desc-title">
                        <div className="yoft-fw-title">
                           Product Manager
                        </div>
                        <div className="yoft-desc">
                           Work in collaboration with the engineers, designers, and business leaders to design, develop and deliver complex, large-scale financial products, involving multiple work streams. Strong technical skills required and experience with analytics platforms and using data to make informed product decisions.
                        </div>
                     </div>
                     <div className="send-resume-btn">
                        <a className="yoft-btn" href="mailto:contact@yoft.xyz">Send Resume</a>
                     </div>
                  </div>


                  <div className="fw-grid">
                     <div className="fw-image">
                        <img src={BlockchainManger} />
                     </div>
                     <div className="yoft-desc-title">
                        <div className="yoft-fw-title">
                           Blockchain Developer
                        </div>
                        <div className="yoft-desc">
                           Build and implement application interfaces and features in accordance with Blockchain needs using the corresponding programming languages. Write top-quality multithreaded Blockchain code. Minimum of two (2) years of experience developing Blockchain technologies.

                        </div>
                     </div>
                     <div className="send-resume-btn">
                        <a className="yoft-btn" href="mailto:contact@yoft.xyz">Send Resume</a>
                     </div>
                  </div>


                  <div className="fw-grid">
                     <div className="fw-image">
                        <img src={BrandAq} />
                     </div>
                     <div className="yoft-desc-title">
                        <div className="yoft-fw-title">
                           Brand Acquisition - EU
                        </div>
                        <div className="yoft-desc">
                           Be the voice of the brand and advocate for YOFT to drive value for the brand ecosystem Conduct market research to identify suitable possibilities and evaluate potential brands' needs. Actively look for new opportunities and manage offline/online sales, negotiate/close deals and handle complaints or objections. Focus on the EU market.

                        </div>
                     </div>
                     <div className="send-resume-btn">
                        <a className="yoft-btn" href="mailto:contact@yoft.xyz">Send Resume</a>
                     </div>
                  </div>


                  <div className="fw-grid">
                     <div className="fw-image">
                        <img src={BrandMena} />
                     </div>
                     <div className="yoft-desc-title">
                        <div className="yoft-fw-title">
                           Brand Acquisition MENA
                        </div>
                        <div className="yoft-desc">
                           Be the voice of the brand and advocate for YOFT to drive value for the brand ecosystem Conduct market research to identify suitable possibilities and evaluate potential brands' needs. Actively look for new opportunities and manage offline/online sales, negotiate/close deals and handle complaints or objections. Focus on the MENA region.                         </div>
                     </div>
                     <div className="send-resume-btn">
                        <a className="yoft-btn" href="mailto:contact@yoft.xyz">Send Resume</a>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>
   );
};

export default Perks;

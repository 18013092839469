/* import React from "react";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";


const BlogPage: React.FC = () => {
  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Blog</title>
      </Helmet>

      <BgGlassmorphism />
   
      <div className="container relative">
      
        <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine5 />
        </div>


        <SectionSubscribe2 className="pb-16 lg:pb-28" />
      </div>
    </div>
  );
};

export default BlogPage;
 */


import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BlogAPI from "api/BlogAPI";
import Loader from "shared/Loader/Loader";

const BlogPage: React.FC = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await BlogAPI.getBlogs();
      if (result.data.data) {
        const sortedBlogs = result.data.data.sort((a: any, b: any) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB.getTime() - dateA.getTime();
        });
        const recentBlogs = sortedBlogs.slice(0, 6);
        setTimeout(() => {
          setBlogs(recentBlogs);
          setIsLoading(false);
        }, 1000);
      }
    })();
  }, []);
  

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Blog</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      <div className="container relative">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {/* === SECTION 1 === */}
            <div className="pt-12 pb-16 lg:pb-28">
              <SectionMagazine5 />
            </div>

            {/* === SECTION 1 === */}
            {/* <SectionAds /> */}

            {/* === SECTION 8 === */}
            {/* <SectionLatestPosts className="py-16 lg:py-28" /> */}

            {/* === SECTION 1 === */}
            <SectionSubscribe2 className="pb-16 lg:pb-28" />
          </>
        )}
      </div>
    </div>
  );
};

export default BlogPage;

import React, { useEffect, useState } from "react";
import SectionSliderBrands from "components/SectionSliderBrands/SectionSliderBrands";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import { useUserData } from "hooks/useUserData";
import BrandAPI from "api/BrandAPI";
import MarketPlaceAPI from "api/MarketPlaceAPI";
import SectionGridFeatureNFT2 from "containers/PageHome/SectionGridFeatureNFT2";
import Loader from "components/Loader";
import SectionListBrands from "components/SectionSliderBrands/SectionListBrands";


function PageBrand() {
    const [brandList, setBrandList] = useState<any>([])
    const { user } = useUserData();
    const [list, setList] = useState([])
    const [isLoader, setisLoader] = useState(true);


    useEffect(() => {
        (async () => {
            const result = await BrandAPI.getBrand();
            const MarketPlace = await MarketPlaceAPI.getMarket({});
            if (result.data) setBrandList(result?.data);
            if (MarketPlace.data.data) setList(MarketPlace.data.data);
            setisLoader(false)
        })()
    }, [])

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
            <title>Brand Partners Listing | Yoft.xyz - Sell More Subscriptions</title>
            <meta name="description" content= "Discover a wide range of brands partnering with Yoft.xyz to increase their subscription sales and retention. Find the perfect brand for you and take your business to the next level with our tradable subscription model" />

            </Helmet>
            {/* GLASSMOPHIN */}
            <BgGlassmorphism />
            <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
                <SectionListBrands heading="Our Brand Partners" isLoader={isLoader} subHeading="Explore the subscriptions from the top brands." data={brandList} />

                <div className="relative py-20 lg:py-28">
                    <SectionGridFeatureNFT2 data={list} isLoader={isLoader} />
                </div>
            </div>

        </div>
    );
}

export default PageBrand;

import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SubscriptionAPI from "api/SubscriptionAPI";
import { catchErrorMessage } from "utils/helper";
import NcModal from "shared/NcModal/NcModal";
import { toast } from "react-toastify";

export interface SectionSubscribe2Props {
  className?: string;
}
export const SubscriptionSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email').required('Required field'),

});

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(SubscriptionSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      if (Object.keys(errors).length > 0) {
        return;
      }
      let body = { ...data }
      await SubscriptionAPI.subscribe(body)
      setShowModal(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      toast.error(catchErrorMessage(e));
    } finally {
      setLoading(false)
    }

  };


  const renderContent = () => {
    return (
      <form action="#">
        <span className="text-sm">
        Thank you for subscribing to our newsletter! Stay tuned for exciting updates and exclusive offers.
        </span>

        {/* <div className="p-5 border bg-white dark:bg-neutral-300 border-neutral-200 dark:border-neutral-700 rounded-xl flex items-center justify-center mt-4">
          <NcImage className="w-40" src={QrCodeImg} />
        </div> */}
      </form>
    );
  };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Stay Up-to-Date with Yoft News!</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-100">
        Subscribe to our newsletter to receive the latest updates, news, and insights about our subscription exchange platform. Stay informed and be the first to know about new features, partnerships, and more.
        </span>
        <ul className="space-y-4 mt-10">     
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Exclusive offers.
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Premium subscriptions.
            </span>
          </li>
        </ul>
        <form className="mt-10 relative max-w-sm" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            aria-required
            render={({ field, fieldState }) => (
              <Input
                type="email"
                placeholder="Enter your email"
                rounded="rounded-full"
                className="mt-1 validate"  {...field} />
            )}
            name="email"
            defaultValue=""
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
          >
            <ArrowSmRightIcon className="w-6 h-6" />
          </ButtonCircle>

        </form>
        <p className='input-error'>{errors?.email?.message}</p>

      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>


      <NcModal
        renderTrigger={() => null}
        isOpenProp={showModal}
        renderContent={renderContent}
        contentExtraClass="max-w-md"
        onCloseModal={() => setShowModal(false)}
        modalTitle="Subscription"
      />

    </div>
  );
};

export default SectionSubscribe2;

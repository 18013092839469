/* import BlogAPI from "api/BlogAPI";
import React, { FC, useEffect, useState } from "react";
import Card12 from "./Card12";
import Card13 from "./Card13";

export interface SectionMagazine5Props {}

const SectionMagazine5: FC<SectionMagazine5Props> = () => {

const [data, setData] = useState<any>([])

useEffect(() => {
    (async () => {
        const result = await BlogAPI.getBlogs();
        if (result.data.data) setData(result.data.data);
    })()
}, []);

  return (
    <div className="nc-SectionMagazine5">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        {
           data.map((ele: any) => <Card12 data={ele} />)
        }
      </div>
    </div>
  );
};

export default SectionMagazine5;
 */

import BlogAPI from "api/BlogAPI";
import React, { FC, useEffect, useState } from "react";
import Card12 from "./Card12";
import Card13 from "./Card13";
import Loader from "shared/Loader/Loader";

export interface SectionMagazine5Props {}

const SectionMagazine5: FC<SectionMagazine5Props> = () => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true); // Set isLoading to true before fetching data
      const result = await BlogAPI.getBlogs();
      if (result.data.data) setData(result.data.data);
      setIsLoading(false); // Set isLoading to false after data has been fetched
    })();
  }, []);

  return (
    <div className="nc-SectionMagazine5">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
          {data.map((ele: any) => (
            <Card12 data={ele} key={ele._id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SectionMagazine5;

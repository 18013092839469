import BlogAPI from "api/BlogAPI";
import React, { FC, useEffect, useState } from "react";
import CardBlogHome from "./CardBlogHome";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";

export interface SectionBlogProps {}

const SectionBlog: FC<SectionBlogProps> = () => {
  const [recentBlogs, setRecentBlogs] = useState<any>([]);

  useEffect(() => {
    (async () => {
      const result = await BlogAPI.getBlogs();
      console.log("blogs ::", result)
      if (result.data.data) {
        const sortedBlogs = result.data.data.sort((a: any, b: any) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB.getTime() - dateA.getTime();
        });
        const recentBlogs = sortedBlogs.slice(0, 6);
        setRecentBlogs(recentBlogs);
      }
    })();
  }, []);

  return (
    <div className="nc-SectionMagazine5">
      <h2 className="text-3xl font-semibold mb-10">Recent Blog Posts</h2>
      <div className="grid lg:grid-cols-3 gap-6 md:gap-8">
        {recentBlogs.map((blog: any, index: number) => (
          <CardBlogHome key={index} data={blog} />
        ))}
      </div>
      <Link to="/blog">
        <ButtonPrimary className="mt-8">See More</ButtonPrimary>
      </Link>
    </div>
  );
};

export default SectionBlog;

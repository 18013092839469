import { web3 } from '../utils/web3'
import { chainId } from '../config/web3.config'

declare module globalThis {
  let ethereum:any
}

const getWeb3 = async (isAuthenticate:any) => {
  if (web3) {
    let web3Data = {
      isLoggedIn: false,
      accounts: [],
    }
    try {
      let responseData;
      let resp;
        responseData = await web3.eth.getAccounts();
        resp = await web3.eth.net.getId()
      if (responseData.length && resp === chainId) {
        web3Data.accounts = responseData;
        if (isAuthenticate || localStorage.getItem('fawToken')) {
          web3Data.isLoggedIn = true;
        }
        return web3Data
      } else {
        return web3Data
      }
    } catch {
      return web3Data
    }
  }
}



const enableMetamask = async () => {
  try {
    await globalThis?.ethereum?.request({ method: 'eth_requestAccounts' })
    localStorage.setItem('isDisconnect', '0')
    const resp = await getWeb3(0)
    return resp
  } catch (error: any) {
    if (error.code === -32002) {
      return {
        error: true,
        code: error.code,
        msg: error.message,
        isLoggedIn: false,
        accounts: [],
      }
    }
    if (error.code === 4001) {
      return {
        error: true,
        code: error.code,
        msg: error.message,
        isLoggedIn: false,
        accounts: [],
      }
    }
    return {
      error: true,
      code: error.code,
      msg: error.message,
      isLoggedIn: false,
      accounts: [],
    }
  }
}

export const web3Services = {
  enableMetamask,
  getWeb3,
}

import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import React from 'react';
import JoinTeam from './components/JoinTeam';
import Perks from './components/Perks';
import { Helmet } from "react-helmet";


const JoinUs = () => {
   return (
    <div>
       <Helmet>
        <title>Careers at YOFT - Join the Team Revolutionizing the Way Subscriptions are Bought and Sold</title>
        <meta name="description" content="Explore exciting career opportunities at Yoft.xyz, the leading platform for buying and selling subscriptions with a unique tradable subscription model. Join our team and be a part of revolutionizing the way subscriptions are bought and sold. Apply now and take your career to the next level!" />
      </Helmet>
         <div className="w-full xl:w-lg space-y-5 lg:space-y-7">
         <h2 className="px-4 lg:px-48 py-[12rem] text-2xl !leading-tight text-center font-semibold text-neutral-900 md:text-4xl xl:text-4xl dark:text-neutral-100">
         Get your front row ticket for all the upcoming growth in the amazing space of web3.0
         </h2>
      </div>
        <Perks />
        <JoinTeam />
        <div className='container pb-24'>
        <SectionSubscribe2 />
        </div>
      </div>
   );
};

export default JoinUs;
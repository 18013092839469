import { API_URLS } from '../constants/endpoints';
import APIAbstract from './APIAbstract';

class StripeAPI extends APIAbstract {

  public createPaymentIntent(payload:any) {
    return this.post(API_URLS.CREATEPAYMENTINTENT, payload);
  }

}

export default new StripeAPI();